import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "homeView full-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackgroundGradiant = _resolveComponent("BackgroundGradiant")!
  const _component_FransysSignature = _resolveComponent("FransysSignature")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_LoginBar = _resolveComponent("LoginBar")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BackgroundGradiant),
    _createVNode(_component_v_col, { class: "pa-0 my-auto" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-auto pb-12" }, {
          default: _withCtx(() => [
            _createVNode(_component_FransysSignature)
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_LoginBar, {
              class: "ma-auto",
              withPassword: true
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}