
import { Vue, Options } from 'vue-class-component';
import API from '@/api/wrapper';
import Spinner from '@/components/UIElements/Spinner.vue';
import BackgroundGradiant from '@/components/UIElements/BackgroundGradiant.vue';
import FransysSignature from '@/components/UIElements/FransysSignature.vue';
import { APIError } from '@/typesAPI';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';


enum ERROR_TYPES {
  RESOURCE_NOT_FOUND = 'resource-not-found',
  TOKEN_MAX_USED = 'token-overused',
  TOKEN_EXPIRED = 'token-expired',
}


@Options({
  components: {
    Spinner,
    BackgroundGradiant,
    FransysSignature,
    CustomButton,
    CustomTextInput
  },
})
export default class TokenLoginView extends Vue {

  email = ""
  loading = false
  error: APIError = {
    title: '',
    details: ''
  }
  meta:any = {}
  ERROR_TYPES: typeof ERROR_TYPES = ERROR_TYPES

  mounted(): void {
    this.loading = false
    API.session.getCSRF()
    .then(() => {
      API.session.tokenLogin(this.$route.query.token as string)
      .then(() => {
        this.$router.replace('flow')
      })
      .catch((err) => {
        if(err?.response?.data?.errors) {
          const errors = Object.values(err.response.data.errors) as any[]
          if(errors.length) {
            this.meta = err.response.data.meta
            this.error = errors[0]
          }
        }
      })
      .finally(() => {
        this.loading = false
      })
    })
    .catch(() => {
      this.loading = false
    })
  }

  generateNewLink(): void {
    this.$router.replace({name:'register', query : {email: this.email}})
  }
}
