import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2af58e98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal-container", {'visible': _ctx.visible, transparent: _ctx.transparent}]),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('clickExternal')))
  }, [
    _createElementVNode("div", {
      class: "modal-bar bar",
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
      style: _normalizeStyle(_ctx.computedStyle)
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
      ])
    ], 4)
  ], 2))
}