import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["project-bar bar py-2 px-4 d-flex", {collapsed:_ctx.collapsed}])
  }, [
    _createVNode(_component_v_col, { class: "ma-auto pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "pa-0 ma-0 d-flex" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "title-container my-auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Fransys "),
                    _createVNode(_component_Badge, {
                      state: _ctx.$enums.BadgeStates.Neutral,
                      outlined: false,
                      class: "ml-4"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Alpha")
                      ]),
                      _: 1
                    }, 8, ["state"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}