import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25f3dedf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeployButton = _resolveComponent("DeployButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["save-bar pa-2 d-flex", {hideLeft: _ctx.hideLeft, firstUnboardingOngoing: _ctx.firstUnboardingOngoing, deployementModalOpen: _ctx.isDeployementModalOpen, deployementModalReduced: _ctx.isDeployementModalReduced, deployed:_ctx.deployed, deploying:_ctx.deploying}])
  }, [
    _createVNode(_component_v_col, { class: "pa-0 ma-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "pa-0 ma-0" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_DeployButton)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}