import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import mitt from 'mitt'

const emitter = mitt()

loadFonts()

const app = createApp(App)
app.config.globalProperties.$emitter = emitter

import enums from '@/enums'
app.config.globalProperties.$enums = enums

import helpers from '@/utils/helpers'
app.config.globalProperties.$helpers = helpers

app.use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')