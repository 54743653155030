
import { Options, Vue } from 'vue-class-component';
import SaveBar from '@/components/Actions/SaveBar.vue';
import ValidationErrorsBar from '@/components/ValidationErrors/ValidationErrorsBar.vue';
import ProjectBar from '@/components/Projects/ProjectBar.vue';
import LogoBar from '@/components/Projects/LogoBar.vue';
import ContactUsBar from '@/components/Actions/ContactUsBar.vue';
import AccountBar from '@/components/Account/AccountBar.vue';

@Options({
  components: {
    SaveBar,
    ValidationErrorsBar,
    ProjectBar,
    LogoBar,
    ContactUsBar,
    AccountBar
  },
})
export default class PanelTop extends Vue {
 
}
