import { Block, Element, ElementNode, ElementLink, Platform, PositionXY } from '@/types'
import { Connection, useVueFlow } from '@vue-flow/core'
import API from '@/api/wrapper'
import { APIBlock, APIConnection, APIFieldBox, APIFieldDefinition, APIFieldValue, APITabField } from '@/typesAPI'
import { unref } from 'vue';

let id = 0
let idLink = 0
const getIdNode = () => `dndnode_${id++}`
const getIdLink = () => `dndnode_link_${idLink++}`
const lockTime = 500;

const vueFlowState = useVueFlow()

const getClosestHandleDirection = (nodeSrcId: string, nodeTargetId: string): string => {
  const nodes = getters.getNodes(state)
  const nodeSrc = nodes.find((node) => node.id === nodeSrcId) as ElementNode
  const nodeTarget = nodes.find((node) => node.id === nodeTargetId) as ElementNode
  let closestDirection = ''
  const nodeWidth = 116
  const nodeHeight = 40
  if(nodeSrc && nodeTarget) {
    const srcTop = {x : nodeSrc.position.x + nodeWidth/2, y: nodeSrc.position.y}
    const srcBottom = {x : nodeSrc.position.x + nodeWidth/2, y: nodeSrc.position.y + nodeHeight}
    const srcRight = {x : nodeSrc.position.x  + nodeWidth, y: nodeSrc.position.y + nodeHeight/2}
    const srcLeft = {x : nodeSrc.position.x, y: nodeSrc.position.y + nodeHeight/2}
  
    const distTop = Math.hypot(nodeTarget.position.x - srcTop.x, nodeTarget.position.y - srcTop.y)
    const distRight = Math.hypot(nodeTarget.position.x - srcRight.x, nodeTarget.position.y - srcRight.y)
    const distBottom = Math.hypot(nodeTarget.position.x - srcBottom.x, nodeTarget.position.y - srcBottom.y)
    const distLeft = Math.hypot(nodeTarget.position.x - srcLeft.x, nodeTarget.position.y - srcLeft.y)
  
    const minDist = Math.min(distTop, distRight, distBottom, distLeft)
  
    if(minDist === distTop) {
      closestDirection = 'top'
    } else if(minDist === distRight) {
      closestDirection = 'right'
    } else if(minDist === distBottom) {
      closestDirection = 'bottom'
    } else if(minDist === distLeft) {
      closestDirection = 'left'
    } 
  }
 
  return closestDirection
}

const createConnectionAPIfromConnection = (state:State, connection:Connection):Promise<APIConnection> => {
  const src = state.list.find((elem:Element) => {
    return elem.id === connection.source
  })
  const target = state.list.find((elem:Element) => {
    return elem.id === connection.target
  })
  return API.connections.create(src?.extra.blockAPIID, target?.extra.blockAPIID)
}

type State = {
  list: (Element)[],
  historic: Element[][],
  indexHistoric: number,
  connectionSuggested:Element | null,
  draggingElement?:Element,
  disappearingElement?:Element,
  detailOpenElement?:ElementNode,
  selectedLink?:ElementLink,
  hoveredLink?:ElementLink,
  detailOpenLink?:ElementLink,
  draggedLink?:ElementLink,
  darggedLinkSourceOrTarget:string,
  isLocked:boolean,
  gridSnapDimension: { height: number, width: number }
}

const state :State = {
  list :  [],
  historic: [[]],
  indexHistoric: 0,
  connectionSuggested: null,
  isLocked: false,
  darggedLinkSourceOrTarget: '',
  gridSnapDimension: { height: 80, width: 464 }
}

const getters = {
  getList: (state:State) => {
    return state.list
  },
  getLinks: (state:State) => {
    return state.list.filter((elem:any) => {
      if(elem.type === 'link' || elem.type === 'default') return elem
    })
  },
  getNodes: (state:State) => {
    return state.list.filter((elem:any) => {
      if(elem.type === 'block') return elem
    })
  },
  getLinkSuggested: (state:State) => {
    return state.connectionSuggested
  },
  getDraggingElement: (state:State) => {
    return state.draggingElement
  },
  getDisappearingElement: (state:State) => {
    return state.disappearingElement
  },
  getDetailOpenElement: (state:State) => {
    return state.detailOpenElement
  },
  getSelectedLink: (state:State) => {
    return state.selectedLink
  },
  getDetailOpenLink: (state:State) => {
    return state.detailOpenLink
  },
  getHoveredLink: (state:State) => {
    return state.hoveredLink
  },
  getDraggedLink: (state:State) => {
    return state.draggedLink
  },
  getDraggedLinkSourceOrTarget: (state:State) => {
    return state.darggedLinkSourceOrTarget
  },
  getGridSnapDimensions: (state:State) => {
    return state.gridSnapDimension
  },
}

const mutations = {
  SET_NODES_FROM_API_BLOCKS: (state:State, payload:{APIblocks:APIBlock[], rootGetters:any}) => {
    payload.APIblocks.forEach((blockAPI) => {
      const block = payload.rootGetters['blocks/getByID'](blockAPI.relationships?.category?.data?.id)
      const newElementNode = {
        id: getIdNode(),
        label: blockAPI.attributes.name,
        position:{ x: blockAPI.attributes.longitude, y: blockAPI.attributes.latitude },
        type: 'block',
        extra: {
          block: block,
          blockAPIID: blockAPI.id
        }
      }
      state.list.push(newElementNode)
    })
  },
  SET_LINKS_FROM_API_CONNECTIONS: (state:State, connectionsAPI:APIConnection[]) => {
    const storeNodes:ElementNode[] = state.list.filter((elem:any) => {
      if(elem.type === 'block') return elem
    }) as ElementNode[]
    connectionsAPI.forEach((connectionAPI:APIConnection) => {
      const leftNode:ElementNode | undefined = storeNodes.find((node:ElementNode) => {
        return node.extra.blockAPIID === connectionAPI.relationships.left.data.id
      })
      const rightNode:ElementNode | undefined = storeNodes.find((node:ElementNode) => {
        return node.extra.blockAPIID === connectionAPI.relationships.right.data.id
      })
      if(leftNode && rightNode) {
        state.list.push({
          id: connectionAPI.id,
          source: leftNode.id,
          target: rightNode.id,
          sourceHandle: leftNode.id + '__handle-'+ getClosestHandleDirection(leftNode.id, rightNode.id),
          targetHandle: rightNode.id + '__handle-'+ getClosestHandleDirection(rightNode.id, leftNode.id),
          type: 'link'
        })
      }
    })
  },
  ADD_NODE: (state:State, payload:{block:Block, type:string, positionXY?:PositionXY, linkSrc?:string, dispatch:any, rootGetters:any}) => {
    const newBlock = {
      id: getIdNode(),
      label: payload.block.name,
      position: payload.positionXY ? payload.positionXY : vueFlowState.project({
        x: document.body.getBoundingClientRect().width / 2,
        y: document.body.getBoundingClientRect().height / 2,
      }),
      type: payload.type,
      extra: {
        block: payload.block,
        blockAPIID: ''
      }
    }
    API.blocks.create(payload.rootGetters['projects/getCurrentProjectId'],payload.block.id, newBlock.position.y, newBlock.position.x)
    .then((result) => {
      newBlock.extra.blockAPIID = result.block.id
      payload.dispatch('blocksAPI/addNode', result.block,  {root:true})
      
      result.fieldsDefinition.forEach((fieldDefinition) => {
        payload.dispatch('fields/addNodeFieldDefinition', fieldDefinition,  {root:true})
      })
      result.fieldsTabs.forEach((fieldTab) => {
        payload.dispatch('fields/addNodeFieldTab', fieldTab,  {root:true})
      })
      result.fieldsBoxes.forEach((fieldBox) => {
        payload.dispatch('fields/addNodeFieldBox', fieldBox,  {root:true})
      })

      result.fieldsValue.forEach((fieldValue) => {
        payload.dispatch('fields/addNodeFieldValue', fieldValue,  {root:true})
        if(payload.block.defaultValueSelected && fieldValue?.relationships?.definition?.data?.id === payload.block?.preselectedFieldDefinitionId) {
          // Set default value
          const def:APIFieldDefinition | undefined = result.fieldsDefinition.find((o) => o.id === payload.block?.preselectedFieldDefinitionId)
          API.blocks.editFieldValue(fieldValue.id, def?.attributes.key as string, payload.block.defaultValueSelected)
          .then((result) => {
            result.blocks.forEach((block) => {
              payload.dispatch('blocksAPI/editBlock', block, {root:true})
            })
            result.fieldsDefinition.forEach((val:APIFieldDefinition) => {
              payload.dispatch('fields/addNodeFieldDefinition',  val, {root:true})
            })
            result.fieldsTabs.forEach((val:APITabField) => {
              payload.dispatch('fields/addNodeFieldTab',  val, {root:true})
            })
            result.fieldsBoxes.forEach((val:APIFieldBox) => {
              payload.dispatch('fields/addNodeFieldBox',  val, {root:true})
            })
            result.fieldsValue.forEach((val:APIFieldValue) => {
              payload.dispatch('fields/addNodeFieldValue',  val, {root:true})
            })

            if(payload.linkSrc) {
              const connection:Connection = {
                source: payload.linkSrc,
                target: newBlock.id,
                sourceHandle: payload.linkSrc + '__handle-right',
                targetHandle: newBlock.id + '__handle-left',
              }
      
              createConnectionAPIfromConnection(state, connection)
              .then((newConnectionAPI:APIConnection) => {
                state.list.push({
                  ...connection,
                  id: newConnectionAPI.id,
                  type: 'link'
                })
                
              })
            }
          })
        }
      })

      state.list.push(newBlock)


      
    })
  },
  REMOVE_NODE: (state:State, payload:{node:Element, delayed:boolean, dispatch:any}) => {
      state.disappearingElement = payload.node
      setTimeout(() => {
        // Delete block
        state.list.splice(state.list.findIndex((elem) => payload.node.id === elem.id) , 1)
        // Delete links connected to block
        const linksToDelete:ElementLink[] = []
        state.list.forEach((elem) => {
          const e = elem as ElementLink
          if(e.source) {
            if (e.source === payload.node.id || e.target === payload.node.id) {
              linksToDelete.push(e)
            }
          }
        })
        linksToDelete.forEach((link) => {
          state.list.splice(state.list.findIndex((elem) => link.id === elem.id) , 1)
        })


        state.disappearingElement = undefined

        //TODO : Change this, it's a copy of the PUSH_HISTO to accomodate the timout
        if(state.indexHistoric === 0) {
          state.historic = [[]]
        }
        state.indexHistoric ++
        state.historic[state.indexHistoric] = JSON.parse(JSON.stringify(state.list))
        state.historic.splice(state.indexHistoric + 1, state.historic.length)
      }, payload.delayed ? 600 : 0)
    
  },
  ADD_LINK: (state:State, payload:{connection:Connection, dispatch:any}) => {
    let alreadyExist = false
    state.list.forEach((elem:any) => {
      if(elem.source === payload.connection.source || elem.source === payload.connection.target) {
        if(elem.target === payload.connection.source || elem.target === payload.connection.target) {
          alreadyExist = true
        }
      }
    })

    if(!alreadyExist) {
      createConnectionAPIfromConnection(state, payload.connection)
      .then((newConnectionAPI:APIConnection) => {
        const newLink = {
          id: newConnectionAPI.id,
          source: payload.connection.source,
          target: payload.connection.target,
          sourceHandle: payload.connection.source + '__handle-'+ getClosestHandleDirection(payload.connection.source, payload.connection.target),
          targetHandle: payload.connection.target + '__handle-'+ getClosestHandleDirection(payload.connection.target, payload.connection.source),
          type: 'link'
        }
        state.list.push(newLink)
      })
    }
   
  },
  REMOVE_LINK: (state:State, payload:{linkID:string, dispatch:any}) => {
    state.list.splice(state.list.findIndex((elem) => payload.linkID === elem.id) , 1)
    API.connections.delete(payload.linkID)
  },
  UPDATE_ELEMENTS: (state:State, list: (Element)[]) => {
    state.list.forEach((elem) => {
      list.forEach((newElem, index) => {
        if (elem.id === newElem.id) {
          elem = newElem
        }
      })
    }) 
  },
  PUSH_HISTORIC: (state:State) => {
    if(state.indexHistoric === 0) {
      state.historic = [[]]
    }
    state.indexHistoric ++
    state.historic[state.indexHistoric] = JSON.parse(JSON.stringify(state.list))
    state.historic.splice(state.indexHistoric + 1, state.historic.length)
  },
  PREV_HISTORIC: (state:State) => {
    if(state.indexHistoric - 1 >= 0) {
      state.indexHistoric--
      state.list = state.historic[state.indexHistoric]
    }
  },
  NEXT_HISTORIC: (state:State) => {
    if(state.indexHistoric + 1 < state.historic.length) {
      state.indexHistoric++
      state.list = state.historic[state.indexHistoric]
    }
  },
  SET_NODE_PARENT: (state:State, payload: {node:ElementNode, parent?:ElementNode}) => {
    state.list.forEach((elem:any) => {
      if (elem.id === payload.node.id) {
        if(payload.parent) {
          elem.parentNode = payload?.parent.id
        } else {
          elem.parentNode = undefined
        }
      }
    }) 
  },
  SET_IS_LINK_SUGGESTED: (state:State, payload: {node:Element, value:boolean}) => {
    if (payload && payload.value) {
      state.connectionSuggested = payload.node
    } else {
      state.connectionSuggested = null
    }
  },
  SET_DRAGGING_ELEMENT: (state:State, node:Element) => {
   state.draggingElement = node
  },
  SET_DETAIL_OPEN_NODE: (state:State, node:ElementNode) => {
    state.detailOpenElement = node
  },
  SET_SELECTED_LINK: (state:State, linkID:string) => {
    state.selectedLink = state.list.find((elem) => elem.id === linkID) as ElementLink
    if(!linkID) {
      state.hoveredLink = undefined
    }
  },
  SET_DETAIL_OPEN_LINK: (state:State, linkID:string) => {
    state.detailOpenLink = state.list.find((elem) => elem.id === linkID) as ElementLink
    if(!linkID) {
      state.hoveredLink = undefined
    }
  },
  SET_HOVERED_LINK: (state:State, linkID:string) => {
    state.hoveredLink = state.list.find((elem) => elem.id === linkID) as ElementLink
  },
  SET_DRAGGED_LINK: (state:State, payload:{ linkID:string, sourceOrTarget:string}) => {
    if(payload) {
      state.draggedLink = state.list.find((elem) => elem.id === payload.linkID) as ElementLink
      state.darggedLinkSourceOrTarget = payload.sourceOrTarget
    } else {
      state.draggedLink = undefined
    }
  },
  CHANGE_LINKS_TYPE: (state:State, type:string) => {
    if(type === 'default') {
      state.selectedLink = undefined
    }
    state.list.forEach((elem) => {
      if(elem.type === 'link' || elem.type === 'default') {
        elem.type = type
      }
    })
  },
  SNAP_LINKS: (state:State,payload:{nodeId: string, context:any}) => {
    // Change position of links
    const myLinks: ElementLink[] = payload.context.getters['getLinks'].filter((link:ElementLink) => {
      return link.source === payload.nodeId || link.target === payload.nodeId
    })
    const allNodes: ElementNode[] = payload.context.getters['getNodes']
    const myElementNode = allNodes.find((elemNode:ElementNode) => {
      return elemNode.id === payload.nodeId
    }) as ElementNode
  
    const updatedLinks:ElementLink[] = []
    myLinks.forEach((link:ElementLink) => {
      if (link.source === payload.nodeId) {
        const targetElementNode = allNodes.find((elemNode:ElementNode) => {
          return elemNode.id === link.target
        }) as ElementNode
        const linkToUpdate = link
        linkToUpdate.sourceHandle =  linkToUpdate.source+ '__handle-'+ getClosestHandleDirection(myElementNode.id, targetElementNode.id)
        linkToUpdate.targetHandle =  linkToUpdate.target+ '__handle-'+ getClosestHandleDirection(targetElementNode.id, myElementNode.id)
        updatedLinks.push(linkToUpdate)
      }
      if (link.target === payload.nodeId) {
        const srcElementNode = allNodes.find((elemNode:ElementNode) => {
          return elemNode.id === link.source
        }) as ElementNode
        const linkToUpdate = link
        linkToUpdate.sourceHandle =  linkToUpdate.source+ '__handle-'+ getClosestHandleDirection(srcElementNode.id, myElementNode.id)
        linkToUpdate.targetHandle =  linkToUpdate.target+ '__handle-'+ getClosestHandleDirection(myElementNode.id, srcElementNode.id)
        updatedLinks.push(linkToUpdate)
      }
    })
    payload.context.dispatch('updateElements', updatedLinks)
  }
}

const actions = {
  setNodesFromAPIBlocks: (context:any, APIblocks:APIBlock[]) => {
    context.commit('SET_NODES_FROM_API_BLOCKS', {APIblocks: APIblocks, rootGetters: context.rootGetters})
  },
  setLinksFromAPIConnections: (context:any, connectionsAPI:APIConnection[]) => {
    context.commit('SET_LINKS_FROM_API_CONNECTIONS', connectionsAPI)
  },
  addNode: (context:any, payload:{block:Block, type:string, positionXY?:PositionXY, linkSrc?:string}) => {
    if(!context.state.isLocked) {
      context.commit('ADD_NODE', {...payload, dispatch:context.dispatch, rootGetters:context.rootGetters})
      context.commit('PUSH_HISTORIC')
      context.state.isLocked = true
      setTimeout(() => {
        context.state.isLocked = false
      },lockTime)
    }
  },
  removeNode: (context:any, payload:{node:Element, delayed:false}) => {
    context.commit('REMOVE_NODE', {node:payload.node, delayed:payload.delayed, dispatch: context.dispatch})
  },
  addLink: (context:any, connection:Connection) => {
    context.commit('ADD_LINK', {connection : connection, dispatch: context.dispatch})
    context.commit('PUSH_HISTORIC')
  },
  removeLink: (context:any, linkID:string) => {
    context.commit('REMOVE_LINK', {linkID: linkID, dispatch: context.dispatch})
    context.commit('PUSH_HISTORIC')
  },
  updateElements: (context:any, list: (Element)[]) => {
    context.commit('UPDATE_ELEMENTS', list)
    context.commit('PUSH_HISTORIC')
  },
  pushHistoric: (context:any) => {
    context.commit('PUSH_HISTORIC')
  },
  prevHistoric: (context:any) => {
    context.commit('PREV_HISTORIC')
  },
  nextHistoric: (context:any) => {
    context.commit('NEXT_HISTORIC')
  },
  setNodeParent: (context:any, payload: {node:Element, parent?:Element}) => {
    context.commit('SET_NODE_PARENT', payload)
  },
  setIsLinkSuggested: (context:any, payload: {node:Element, value:boolean}) => {
    context.commit('SET_IS_LINK_SUGGESTED', payload)
  },  
  setDraggingElement: (context:any, payload:{node:Element, delayed:boolean}) => {
    context.commit('SET_DRAGGING_ELEMENT', payload)
  },
  setDetailOpenElement: (context:any, node:ElementNode) => {
    context.commit('SET_DETAIL_OPEN_NODE', node)
  },
  setSelectedLink: (context:any, linkID:string) => {
    context.commit('SET_SELECTED_LINK', linkID)
  },
  setDetailOpenLink: (context:any, linkID:string) => {
    context.commit('SET_DETAIL_OPEN_LINK', linkID)
  },
  setHoveredLink: (context:any, linkID:string) => {
    context.commit('SET_HOVERED_LINK', linkID)
  },
  setDraggedLink: (context:any, payload:{ linkID:string,  sourceOrTarget:string}) => {
    context.commit('SET_DRAGGED_LINK', payload)
  },
  changeLinksType: (context:any, type:string) => {
    context.commit('CHANGE_LINKS_TYPE', type)
  },
  snapLinks: (context:any, nodeId:string) => {
    context.commit('SNAP_LINKS', {nodeId: nodeId, context: context})
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}