
import { Vue, Options } from 'vue-class-component'
import Logo from '@/components/UIElements/Logo.vue'
import Badge from '@/components/UIElements/Badge.vue'

@Options({
  components: {
    Logo,
    Badge 
  },
})
export default class LogoBar extends Vue {
  // FIRST UNBOARDING
  get collapsed () {
    return !this.$store.getters['app/getFirstUnboardingDone']
  }
  // END FIRST UNBOARDING
}
