import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e40a1002"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "search-container"
}
const _hoisted_2 = {
  key: 1,
  class: "dropdown-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_List = _resolveComponent("List")!
  const _component_v_card = _resolveComponent("v-card")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown-menu", {hidden:!_ctx.show, 'max-content': _ctx.maxContent}]),
    ref: "dropdownMenu",
    style: _normalizeStyle('width:' + _ctx.width+ '; top:'+_ctx.top+ '; left:'+_ctx.left + '; right:'+_ctx.right + '; bottom:'+_ctx.bottom)
  }, [
    (_ctx.show)
      ? _withDirectives((_openBlock(), _createBlock(_component_v_card, {
          key: 0,
          elevation: "0"
        }, {
          default: _withCtx(() => [
            (_ctx.displaySearch)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_CustomTextInput, {
                    class: "ma-4",
                    placeholder: "Search",
                    icon: "search",
                    searchStyle: true,
                    modelValue: _ctx.query,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:query', $event)))
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "expanded-select-items-container",
              style: _normalizeStyle('max-height:' + _ctx.maxHeight+'px;')
            }, [
              _createVNode(_component_List, {
                items: _ctx.items,
                onSelect: _ctx.onSelect,
                loading: _ctx.loading
              }, null, 8, ["items", "onSelect", "loading"])
            ], 4),
            (_ctx.$slots['footer'])
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 3
        })), [
          [_directive_click_outside, _ctx.onOutsideClick]
        ])
      : _createCommentVNode("", true)
  ], 6))
}