import { APIUser, APISocialAccount } from '@/typesAPI'
import { SubscriptionState } from '@/enums'

type State = {
  user: APIUser | null,
  socialAccountsByProvider: {[key:string]: APISocialAccount}
}

const state: State = {
  user: null,
  socialAccountsByProvider: {}
}


const getters = {
  getUser: (state:State) => {
    return state.user
  },
  getSocialAccountsByProvider: (state:State) => {
    return state.socialAccountsByProvider
  },
  getSubscriptionState: (state:State): string => {
    if(state.user) {
      return state.user.meta.subscription_status
    }
    return SubscriptionState.FRESH
  },
}

const mutations = {
  SET_USER: (state:State, user:APIUser) => {
    state.user = user
  },
  SET_SOCIAL_ACCOUNT: (state:State, payload: {provider:string, socialAccount:APISocialAccount}) => {
    state.socialAccountsByProvider[payload.provider] = payload.socialAccount
  },
  DELETE_SOCIAL_ACCOUNT: (state:State, provider:string) => {
    delete state.socialAccountsByProvider[provider]
  },
  START_TRIAL: (state:State) => {
    if(state.user) {
      state.user.meta.subscription_status = SubscriptionState.TRIALING
    }
  },
}

const actions = {
  setUser: (context:any, user:APIUser) => {
    context.commit('SET_USER', user)
  },
  setSocialAccount: (context:any,  payload: {provider:string, socialAccount:APISocialAccount}) => {
    context.commit('SET_SOCIAL_ACCOUNT', payload)
  },
  deleteSocialAccount: (context:any,  provider:string) => {
    context.commit('DELETE_SOCIAL_ACCOUNT', provider)
  },
  startTrial: (context:any) => {
    context.commit('START_TRIAL')
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}