import axios from 'axios';
import { APIErrorValidation, APISocialAccount, APIBlockCategory, APIUser, APIFieldDefinition, APITabField, APIBlock, APIFieldValue, APIConnection, APIEnvironmentVariable, APIBlockEnvironmentVariableOverrides, APIDeploymentVersion, APIDeploymentPlan, APIFieldBox, APIBaseAttributes, APIUrlRedirection, APISelectOption, APIDeploymentSubject, APIDeploymentRun, APIDeploymentLog } from '@/typesAPI';
import getEnv from '@/utils/env'
import helpers from '@/utils/helpers'
import router from '@/router';
import { RouteRecordRaw } from 'vue-router';

const csrfCookieName = 'XSRF-TOKEN'
const instance = axios.create({
  baseURL: getEnv('VUE_APP_API_URL'),
  timeout: 10000,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-CSRF-TOKEN',
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    "Content-Type": "application/json"
  },
  validateStatus: function(status: number) {
    return (status >= 200 && status < 300);
  }
});

// const exeptionAutoErrorEndpoints = [/\/me$/];
// const exeptionAutoError = ["resource-not-found"];

instance.interceptors.response.use(
  (res: any) => {
    //OnEverySuccess
    return res;
  },
  (err: any) => {
    if((err.response.status === 401)) {
      const route:RouteRecordRaw | undefined = router.options.routes.find((route:RouteRecordRaw) => {
        return route.name === router.currentRoute.value.name
      })
      if(route && (route?.props as any)?.needAuth) {
        router.push({name: 'login'})
      }
    }
    //OnEveryError
    return Promise.reject(err);
  }
);

function extractFromIncludes(result:any, type:string) {
  return result.data?.included ? result.data?.included.filter((item:any) => item.type === type) : []
} 
function isCSRFCookiePresent() {
  return document.cookie.includes(csrfCookieName + '=')
} 
const API = {
  get instance () {
    return instance
  },
  session: {
    getCSRF() {
      return new Promise((resolve, reject) => {
        if (!isCSRFCookiePresent()) {
          helpers.console.log("Session/getCSRF START")
          instance
          .get("/sanctum/csrf-cookie")
          .then((result) => {
            helpers.console.log("Session/getCSRF SUCCESS : ", result)
            resolve(result)
          })
          .catch((err: any) => {
            helpers.console.warn("Session/getCSRF FAIL : ", err)
            reject(err);
          });
        } else {
          helpers.console.log("CSRF Already present")
          resolve(null)
        }
      });
    },
    login(login: string, password: string) {
      helpers.console.log("Session/login START")
      return new Promise((resolve, reject) => {
        instance
        .post("/alpha/login", {
          login: login,
          password: password
        })
        .then((result: any) => {
          helpers.console.log("Session/login SUCCESS : ", result)
          resolve(result.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Session/login FAIL : ", err)
          reject(err);
        });
      });
    },
    loginViaSocial(socialProvider: string): Promise<APIUrlRedirection> {
      helpers.console.log("Session/loginViaSocial START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/login/${socialProvider}/redirect`)
        .then((result: any) => {
          helpers.console.log("Session/loginViaSocial SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Session/loginViaSocial FAIL : ", err)
          reject(err);
        });
      });
    },
    loginViaSocialCallback(socialProvider: string, code:string): Promise<APIUser> {
      helpers.console.log("Session/loginViaSocialCallback START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/login/${socialProvider}/callback`, {
          code : code
        })
        .then((result: any) => {
          helpers.console.log("Session/loginViaSocialCallback SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Session/loginViaSocialCallback FAIL : ", err)
          reject(err);
        });
      });
    },
    createMagicLink(email: string) {
      helpers.console.log("Session/createMagicLink START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/magicLink`, {email:email})
        .then((result: any) => {
          helpers.console.log("Session/createMagicLink SUCCESS : ", result)
          resolve(result.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Session/createMagicLink FAIL : ", err)
          reject(err);
        });
      });
    },
    tokenLogin(token: string) {
      helpers.console.log("Session/tokenLogin START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/tokens/${token}`)
        .then((result: any) => {
          helpers.console.log("Session/tokenLogin SUCCESS : ", result)
          resolve(result.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Session/tokenLogin FAIL : ", err)
          reject(err);
        });
      });
    },
    logout() {
      helpers.console.log("Session/logout START")
      return new Promise((resolve, reject) => {
        instance
        .post("/alpha/logout")
        .then((result: any) => {
          helpers.console.log("Session/logout SUCCESS : ", result)
          resolve(result.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Session/logout FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  subscription: {
    checkout():Promise<APIUrlRedirection> {
      helpers.console.log("Subscription/checkout START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/checkoutSessions`)
        .then((result: any) => {
          helpers.console.log("Subscription/checkout SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Subscription/checkout FAIL : ", err)
          reject(err);
        });
      });
    },
    manage():Promise<APIUrlRedirection> {
      helpers.console.log("Subscription/manage START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/subscriptions/default`)
        .then((result: any) => {
          helpers.console.log("Subscription/manage SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Subscription/manage FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  user: {
    getMe():Promise<{user:APIUser, socialAccounts:APISocialAccount[]}> {
      helpers.console.log("User/me START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/me?include=social_accounts")
        .then((result: any) => {
          helpers.console.log("User/me SUCCESS : ", result)
          resolve({user: result.data.data, socialAccounts: extractFromIncludes(result, 'socialAccounts')});
        })
        .catch((err: any) => {
          helpers.console.warn("User/me FAIL : ", err)
          reject(err);
        });
      });
    },
    create(email:string):Promise<APIUser> {
      helpers.console.log("User/create START")
      return new Promise((resolve, reject) => {
        instance
        .post("/alpha/users", {email:email})
        .then((result: any) => {
          helpers.console.log("User/create SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("User/create FAIL : ", err)
          reject(err);
        });
      });
    },
    acceptCGU(userId:string):Promise<APIUser> {
      helpers.console.log("User/acceptCGU START")
      return new Promise((resolve, reject) => {
        instance
        .patch(`/alpha/users/${userId}`, {terms_of_use_accepted:true})
        .then((result: any) => {
          helpers.console.log("User/acceptCGU SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("User/acceptCGU FAIL : ", err)
          reject(err);
        });
      });
    }
  },
  blocks: {
    getIndex(projectId:string): Promise<{blocks:APIBlock[], fieldsValue:APIFieldValue[], fieldsDefinition:APIFieldDefinition[], fieldsTabs: APITabField[], connections: APIConnection[], fieldsBoxes:APIFieldBox[]}> {
      helpers.console.log("Blocks/getIndex START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/projects/"+projectId+"/blocks?include=fields,fields.definition,fields.definition.box,fields.definition.box.tab,current_connections")
        .then((result: any) => {
          helpers.console.log("Blocks/getIndex SUCCESS : ", result)
          const fieldsValue = extractFromIncludes(result, 'fields')
          const fieldsDefinition = extractFromIncludes(result, 'fieldDefinitions')
          const fieldsTabs = extractFromIncludes(result, 'blockTabs')
          const connections = extractFromIncludes(result, 'blockConnections')
          const boxes = extractFromIncludes(result, 'fieldBoxes')

          resolve({blocks: result.data.data, fieldsValue: fieldsValue, fieldsDefinition:fieldsDefinition, fieldsTabs:fieldsTabs, connections: connections, fieldsBoxes: boxes});
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/getIndex FAIL : ", err)
          reject(err);
        });
      });
    },
    getCategories(): Promise<{blockCategories:APIBlockCategory[], definitions: APIFieldDefinition[]}> {
      helpers.console.log("Blocks/getCategories START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/blockCategories?include=preselect_field_definition")
        .then((result: any) => {
          helpers.console.log("Blocks/getCategories SUCCESS : ", result)
          resolve({
            blockCategories: result.data.data as APIBlockCategory[],
            definitions: result.data.included as APIFieldDefinition[]
          });
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/getCategories FAIL : ", err)
          reject(err);
        });
      });
    },
    create(projectId:string, blockCategoryID:string, latitude:number, longitude: number): Promise<{block:APIBlock, fieldsValue:APIFieldValue[], fieldsDefinition:APIFieldDefinition[], fieldsTabs: APITabField[], fieldsBoxes:APIFieldBox[]}> {
      helpers.console.log("Blocks/create START")
      return new Promise((resolve, reject) => {
        instance
        .post("/alpha/projects/"+projectId+"/blockCategories/" + blockCategoryID + "/blocks?include=fields,fields.definition,fields.definition.box,fields.definition.box.tab", {latitude : parseInt(latitude.toString()), longitude : parseInt(longitude.toString())})
        .then((result: any) => {
          helpers.console.log("Blocks/create SUCCESS : ", result)
          const fieldsValue = extractFromIncludes(result, 'fields')
          const fieldsDefinition = extractFromIncludes(result, 'fieldDefinitions')
          const fieldsTabs = extractFromIncludes(result, 'blockTabs')
          const fieldsBoxes = extractFromIncludes(result, 'fieldBoxes')
          
          resolve({block: result.data.data, fieldsValue: fieldsValue, fieldsDefinition:fieldsDefinition, fieldsTabs:fieldsTabs, fieldsBoxes:fieldsBoxes});
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/create FAIL : ", err)
          reject(err);
        });
      });
    },
    delete(blockAPIID:string): Promise<any> {
      helpers.console.log("Blocks/delete START")
      return new Promise((resolve, reject) => {
        instance
        .delete("/alpha/blocks/" + blockAPIID)
        .then((result: any) => {
          helpers.console.log("Blocks/delete SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/delete FAIL : ", err)
          reject(err);
        });
      });
    },
    editBlockName(blockAPIID:string, name:string): Promise<any> {
      helpers.console.log("Blocks/editBlockName START")
      return new Promise((resolve, reject) => {
        instance
        .patch("/alpha/blocks/" + blockAPIID, {name:name})
        .then((result: any) => {
          helpers.console.log("Blocks/editBlockName SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/editBlockName FAIL : ", err)
          reject(err);
        });
      });
    },
    editBlockPosition(blockAPIID:string, latitude:number, longitude: number): Promise<any> {
      helpers.console.log("Blocks/editBlockPosition START")
      return new Promise((resolve, reject) => {
        instance
        .patch("/alpha/blocks/" + blockAPIID, {latitude:latitude, longitude: longitude})
        .then((result: any) => {
          helpers.console.log("Blocks/editBlockPosition SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/editBlockPosition FAIL : ", err)
          reject(err);
        });
      });
    },
    editFieldValue(fieldValueID:string, key:string, value:string): Promise<{newFieldValue:APIFieldValue ,blocks:APIBlock[], fieldsValue:APIFieldValue[], fieldsDefinition:APIFieldDefinition[], fieldsTabs: APITabField[], fieldsBoxes:APIFieldBox[]}> {
      helpers.console.log("Blocks/editFieldValue START")
      return new Promise((resolve, reject) => {
        instance
        .patch("/alpha/fields/" + fieldValueID + '?include=block,block.fields,block.fields.definition,block.fields.definition.box,block.fields.definition.box.tab', {value:value})
        .then((result: any) => {
          helpers.console.log("Blocks/editFieldValue SUCCESS : ", result)
          const blocks = extractFromIncludes(result, 'blocks')
          const fieldsValue = extractFromIncludes(result, 'fields')
          const fieldsDefinition = extractFromIncludes(result, 'fieldDefinitions')
          const fieldsTabs = extractFromIncludes(result, 'blockTabs')
          const fieldsBoxes = extractFromIncludes(result, 'fieldBoxes')
          resolve({newFieldValue: result.data.data, blocks: blocks, fieldsValue: fieldsValue, fieldsDefinition:fieldsDefinition, fieldsTabs:fieldsTabs, fieldsBoxes: fieldsBoxes});
        })
        .catch((err: any) => {
          helpers.console.warn("Blocks/editFieldValue FAIL : ", err)
          reject(err);
        });
      });
    }
  },
  connections: {
    create(firstBlockAPIID: string, secondBlockAPIID:string): Promise<APIConnection> {
      helpers.console.log("Connections/create START")
      return new Promise((resolve, reject) => {
        instance
        .post("/alpha/blockConnections", { first_block_id: firstBlockAPIID, second_block_id: secondBlockAPIID })
        .then((result: any) => {
          helpers.console.log("Connections/create SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Connections/create FAIL : ", err)
          reject(err);
        });
      });
    },
    delete(connectionID: string): Promise<any> {
      helpers.console.log("Connections/delete START")
      return new Promise((resolve, reject) => {
        instance
        .delete("/alpha/blockConnections/" + connectionID)
        .then((result: any) => {
          helpers.console.log("Connections/delete SUCCESS : ", result)
          resolve(result);
        })
        .catch((err: any) => {
          helpers.console.warn("Connections/delete FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  actions : {
    deploy(versionId:string): Promise<any> {
      helpers.console.log("Actions/deploy START")
      return new Promise((resolve, reject) => {
        instance
        .patch(`/alpha/deploymentVersions/${versionId}`, {
          status: 'running'
        })
        .then((result: any) => {
          helpers.console.log("Actions/deploy SUCCESS : ", result)
          resolve(null);
        })
        .catch((err: any) => {
          helpers.console.warn("Actions/deploy FAIL : ", err)
          reject(err);
        });
      });
    },
    validationErrors(projectId:string): Promise<APIErrorValidation[]> {
      helpers.console.log("Actions/checkErrors START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/projects/" + projectId + "/validationErrors")
        .then((result: any) => {
          helpers.console.log("Actions/checkErrors SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Actions/checkErrors FAIL : ", err)
          reject(err);
        });
      });
    },
   
  },
  search: {
    fieldSelectValues(fieldId:string, query:string): Promise<APISelectOption[]> {
      helpers.console.log("Search/fieldSelectValues START")
      return new Promise((resolve, reject) => {
        const queryString = query ? `?query=${query}` : ''
        instance
        .get(`/alpha/selects/${fieldId}${queryString}`)
        .then((result: any) => {
          helpers.console.log("Search/fieldSelectValues SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Search/fieldSelectValues FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  buttons: {
    post(fieldId:string): Promise<any> {
      helpers.console.log("Buttons/post START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/buttons/${fieldId}`)
        .then((result: any) => {
          helpers.console.log("Buttons/post SUCCESS : ", result)
          resolve(result);
        })
        .catch((err: any) => {
          helpers.console.warn("Buttons/post FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  group: {
    create(fieldDefinitionId:string, body:{[key:string]:string}) {
      helpers.console.log("Group/create START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/fields/${fieldDefinitionId}/fieldGroups?include=fields`, body)
        .then((result: any) => {
          helpers.console.log("Group/create SUCCESS : ", result)
          resolve(result);
        })
        .catch((err: any) => {
          helpers.console.warn("Group/create FAIL : ", err)
          reject(err);
        });
      });
    },
    delete(fieldGroupId:string) {
      helpers.console.log("Group/delete START")
      return new Promise((resolve, reject) => {
        instance
        .delete(`/alpha/fieldGroups/${fieldGroupId}`)
        .then((result: any) => {
          helpers.console.log("Group/delete SUCCESS : ", result)
          resolve(result);
        })
        .catch((err: any) => {
          helpers.console.warn("Group/delete FAIL : ", err)
          reject(err);
        });
      });
    }
  },
  social: {
    connect(userId:string, socialProvider:string, redirectUrl:string): Promise<APIUrlRedirection> {
      helpers.console.log("Social/connect START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/users/${userId}/socialAccounts/${socialProvider}/redirect`,{
          params: {
            redirect_url: redirectUrl,
          }
        })
        .then((result: any) => {
          helpers.console.log("Social/connect SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Social/connect FAIL : ", err)
          reject(err);
        });
      });
    },
    callback(userId:string, socialProvider:string, code:string, redirectUrl:string): Promise<APISocialAccount> {
      helpers.console.log("Social/callback START")
      return new Promise((resolve, reject) => {
        instance
        .post(`/alpha/users/${userId}/socialAccounts/${socialProvider}/callback`, {code: code, redirect_url:redirectUrl})
        .then((result: any) => {
          helpers.console.log("Social/callback SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Social/callback FAIL : ", err)
          reject(err);
        });
      });
    },
    get(accountId:string): Promise<APISocialAccount> {
      helpers.console.log("Social/get START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/socialAccounts/${accountId}`)
        .then((result: any) => {
          helpers.console.log("Social/get SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Social/get FAIL : ", err)
          reject(err);
        });
      });
    },
    delete(accountId:string): Promise<any> {
      helpers.console.log("Social/delete START")
      return new Promise((resolve, reject) => {
        instance
        .delete(`/alpha/socialAccounts/${accountId}`)
        .then((result: any) => {
          helpers.console.log("Social/delete SUCCESS : ", result)
          resolve(result);
        })
        .catch((err: any) => {
          helpers.console.warn("Social/delete FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  environmentVariables: {
    get(blockID:string): Promise<any> {
      helpers.console.log("EnvironmentVariables/get START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/blocks/" + blockID + "/blockEnvironmentVariables")
        .then((result: any) => {
          helpers.console.log("EnvironmentVariables/get SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("EnvironmentVariables/get FAIL : ", err)
          reject(err);
        });
      });
    },
    create(blockID:string, attributes:APIEnvironmentVariable['attributes']): Promise<APIEnvironmentVariable> {
      helpers.console.log("EnvironmentVariables/create START")
      return new Promise((resolve, reject) => {
        instance
        .post("/alpha/blocks/" + blockID + "/blockEnvironmentVariables", attributes)
        .then((result: any) => {
          helpers.console.log("EnvironmentVariables/create SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("EnvironmentVariables/create FAIL : ", err)
          reject(err);
        });
      });
    },
    edit(variableID:string, attributes:APIEnvironmentVariable['attributes']): Promise<any> {
      helpers.console.log("EnvironmentVariables/edit START")
      const payload:any = attributes
      Object.keys(payload).forEach((key:string) => {
        if(payload[key] === null) {
          delete payload[key]
        }
      })
      return new Promise((resolve, reject) => {
        instance
        .patch("/alpha/blockEnvironmentVariables/"+ variableID, payload)
        .then((result: any) => {
          helpers.console.log("EnvironmentVariables/edit SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("EnvironmentVariables/edit FAIL : ", err)
          reject(err);
        });
      });
    },
    delete(variableID:string): Promise<null> {
      helpers.console.log("EnvironmentVariables/delete START")
      return new Promise((resolve, reject) => {
        instance
        .delete("/alpha/blockEnvironmentVariables/"+ variableID)
        .then((result: any) => {
          helpers.console.log("EnvironmentVariables/delete SUCCESS : ", result)
          resolve(null);
        })
        .catch((err: any) => {
          helpers.console.warn("EnvironmentVariables/delete FAIL : ", err)
          reject(err);
        });
      });
    },
    getOverride(blockConnectionID:string): Promise<APIBlockEnvironmentVariableOverrides[]> {
      helpers.console.log("EnvironmentVariables/getOverride START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/blockConnections/" + blockConnectionID + "/blockEnvironmentVariableOverrides")
        .then((result: any) => {
          helpers.console.log("EnvironmentVariables/getOverride SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("EnvironmentVariables/getOverride FAIL : ", err)
          reject(err);
        });
      });
    },
    editOverride(overrideID:string, newName:string): Promise<any> {
      helpers.console.log("EnvironmentVariables/editOverride START")
      return new Promise((resolve, reject) => {
        instance
        .patch("/alpha/blockEnvironmentVariableOverrides/" + overrideID, {name: newName})
        .then((result: any) => {
          helpers.console.log("EnvironmentVariables/editOverride SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("EnvironmentVariables/editOverride FAIL : ", err)
          reject(err);
        });
      });
    },
  },
  sockets: {
    auth(callback:any, payload:any):any {
      instance
      .post("/broadcasting/auth", payload)
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        callback(error);
      });
    }
  },
  deployment: {
    getLogs(deploymentPlanId:string): Promise<APIDeploymentLog[]> {
      helpers.console.log("Deployment/getLogs START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/deploymentPlans/${deploymentPlanId}/logs`)
        .then((result: any) => {
          helpers.console.log("Deployment/getLogs SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Deployment/getLogs FAIL : ", err)
          reject(err);
        });
      });
    },
    includeDeploymentPlanInVersion(deploymentPlanId:string, value:boolean): Promise<APIDeploymentPlan> {
      helpers.console.log("Deployment/includeDeploymentPlanInVersion START")
      return new Promise((resolve, reject) => {
        instance
        .patch(`/alpha/deploymentPlans/${deploymentPlanId}`, {
          include_in_version: value
        })
        .then((result: any) => {
          helpers.console.log("Deployment/includeDeploymentPlanInVersion SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Deployment/includeDeploymentPlanInVersion FAIL : ", err)
          reject(err);
        });
      });
    },
    getVersions(projectId:string): Promise<APIDeploymentVersion> {
      helpers.console.log("Deployment/getVersions START")
      return new Promise((resolve, reject) => {
        instance
        .get("/alpha/projects/" + projectId + "/deploymentVersions")
        .then((result: any) => {
          helpers.console.log("Deployment/getVersions SUCCESS : ", result)
          resolve(result.data.data);
        })
        .catch((err: any) => {
          helpers.console.warn("Deployment/getVersions FAIL : ", err)
          reject(err);
        });
      });
    },
    getDeploymentPlans(deploymentVersion:string): Promise<{deploymentPlans:APIDeploymentPlan[], subjects:APIDeploymentSubject[]}> {
      helpers.console.log("Deployment/getDeploymentPlans START")
      return new Promise((resolve, reject) => {
        instance
        .get(`/alpha/deploymentVersions/${deploymentVersion}/deploymentPlans?include=subject`)
        .then((result: any) => {
          helpers.console.log("Deployment/getDeploymentPlans SUCCESS : ", result)
          resolve({
            deploymentPlans: result.data.data,
            subjects:result.data.included,
          });
        })
        .catch((err: any) => {
          helpers.console.warn("Deployment/getDeploymentPlans FAIL : ", err)
          reject(err);
        });
      });
    },
  },
};

export default API;
