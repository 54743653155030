
import { unref } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';
import { NodeProps, Position, Handle, Connection, useVueFlow, StartHandle, NodeDragEvent, Rect } from '@vue-flow/core'
import BlockBarContext from '@/components/Blocks/BlockBarContext.vue';
import { Watch } from 'vue-property-decorator'
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'
import { Block, Element, ElementNode } from '@/types';
import { APIBaseAttributes, APIBlock, APIErrorValidation } from '@/typesAPI';

class Props {
  props: NodeProps = prop({
    required: true,
  });
}

@Options({
  components: {
    Handle,
    BlockBarContext,
    Icon,
    Logo
  },
})
export default class BlockNode extends Vue.with(Props) {
  Position:typeof Position = Position
  isHovered = false
  isDraggedOver = false
  isDragged = false
  showBlockBar = false

  vueFlowState = useVueFlow()

  created(): void {
    this.vueFlowState.onNodeDrag((event:NodeDragEvent) => {
      this.isDragged = event.node.id === this.props.id
    })
    this.vueFlowState.onNodeDragStop((event:NodeDragEvent) => {
      this.isDragged = false
    })

    this.$emitter.on('dragLink', (payload:{x: number, y:number, sourceOrTarget:string}) => {
      const linkRect = {width : 1, height : 1, ...this.vueFlowState.project({x: payload.x, y: payload.y}) }
      this.isDraggedOver = this.vueFlowState.isNodeIntersecting(this.nodeRect, linkRect)
    })

  }

  dragLink(event:DragEvent, source:string) {
    this.$emitter.emit('dragLink', {x: event.x, y: event.y, sourceOrTarget: source})
  }

  dragLinkStart(event:DragEvent, sourceOrTarget:string) {
    console.log('dragLinkStart', this.props.label)
    this.$store.dispatch('elements/setDraggedLink', { linkID: this.$store.getters['elements/getSelectedLink'].id, sourceOrTarget: sourceOrTarget })
  }

  dragLinkEnd(event:DragEvent) {
    console.log('dragLinkEnd', this.props.label)
    this.$store.dispatch('elements/setDraggedLink')
  }

  get validationErrors(): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getBySourceID'](this.blockAPIID)
  }

  get pictoWarningSeverity(): string {
    let ret = ''
    this.validationErrors.forEach((err:APIErrorValidation) => {
      if(err.meta.severity === 'error') {
        ret = 'error'
      } else if(err.meta.severity === 'warning' && ret !== 'error') {
        ret = 'warning'
      }
    })

    return ret ? ret : 'info'
  }

  get node(): ElementNode | undefined  {
    const nodes = this.$store.getters['elements/getNodes']
    return nodes.find((node:ElementNode) => node.id === this.props.id)
  }

  get nodeRect(): Rect  {
    return { ...this.props.dimensions, ...this.props.position}
  }

  get block(): Block {
    const nodes = this.$store.getters['elements/getNodes']
    const node = nodes.find((node:Element) => node.id === this.props.id)
    return node && node.extra ? node.extra.block : {label : '', id : this.props.id}
  }

  get blockAPIID(): string {
    const nodes = this.$store.getters['elements/getNodes']
    const node = nodes.find((node:Element) => node.id === this.props.id)
    return node && node.extra ? node.extra.blockAPIID : ''
  }

  get blockAPI(): APIBlock {
    const blocksAPI = this.$store.getters['blocksAPI/getList']
    return blocksAPI.find((b:APIBlock) => b.id === this.blockAPIID)
  }

  get blockName(): string {
    return this.blockAPI.attributes.name ? this.blockAPI.attributes.name : this.blockAPI.attributes.default_name
  }

  get isValidationErrorHovered(): boolean  {
    let ret = false
    const error:APIErrorValidation | null = this.$store.getters['errorsValidation/getHoveredError']
    if(error) {
      if(error.relationships.source.data.id === this.blockAPIID) {
        ret = true
      }
      if(this.blockAPI.relationships?.fields.data.find((fv:APIBaseAttributes) => {
        return fv.id === error.relationships.source.data.id
      })) {
        ret = true
      }
    } 
    return ret
  }

  get isConnectionStarted(): boolean {
    const connectionStartHandle: StartHandle | null = unref(this.vueFlowState.connectionStartHandle)
    return !!connectionStartHandle 
  }

  get isConnectionSuggested(): boolean {
    const connectionStartHandle: StartHandle | null = unref(this.vueFlowState.connectionStartHandle)
    return ((!!connectionStartHandle && connectionStartHandle.nodeId !== this.props.id) && this.isHovered ) || ( this.isLinkDragged && this.isDraggedOver)
  }

  get isStartOfConnection(): boolean {
    const connectionStartHandle: StartHandle | null = unref(this.vueFlowState.connectionStartHandle)
    return !!connectionStartHandle && connectionStartHandle.nodeId === this.props.id
  }

  get listConnections(): Connection[] {
    return this.$store.getters['elements/getLinks']
  }

  get isDisappearing(): Connection[] {
    const disappearingElem = this.$store.getters['elements/getDisappearingElement']
    return disappearingElem && disappearingElem.id === this.props.id
  }

  get isSource() {
    return !!this.listConnections.find((connection: Connection) => connection.source === this.props.id )
  }

  get isTarget() {
    return !!this.listConnections.find((connection: Connection) => connection.target === this.props.id )
  }

  get IsButtonDeployed () {
    return (this.isHovered && !this.isConnectionSuggested && !this.isDragged && !this.showBlockBar && !this.isDisappearing)
  }

  get isLinkLeftSelected () {
    const selectedLink = this.$store.getters['elements/getSelectedLink']
    if (selectedLink && this.node) {
      return selectedLink.target === this.node.id
    }
    return false
  }
  get isLinkRightSelected () {
    const selectedLink = this.$store.getters['elements/getSelectedLink']
    if (selectedLink && this.node) {
      return selectedLink.source === this.node.id
    }
    return false
  }

  get isLinkLeftHovered () {
    const hoveredLink = this.$store.getters['elements/getHoveredLink']
    if (hoveredLink && this.node) {
      return hoveredLink.target === this.node.id
    }
    return false
  }
  get isLinkRightHovered () {
    const hoveredLink = this.$store.getters['elements/getHoveredLink']
    if (hoveredLink && this.node) {
      return hoveredLink.source === this.node.id
    }
    return false
  }

  get isLinkDragged () {
    return !!this.$store.getters['elements/getDraggedLink']
  }

  onMouseEnter() {
    this.isHovered = true
  }

  onMouseLeave() {
    this.isHovered = false
    this.showBlockBar = false
  }

  onAddNode() {
    this.showBlockBar = false
    this.isHovered = false
  }

  onClick() {
    this.$store.dispatch('elements/setDetailOpenElement', this.node)
  }

  @Watch('isConnectionSuggested')
  onIsConnectionSuggestedChange(newVal:boolean) {
    this.$store.dispatch('elements/setIsLinkSuggested', {node: this.props, value: newVal})
  }

  get positionXYNewBlock() {
    const ret = {x: 0, y: 0}
    ret.x = this.props.position.x + 350
    ret.y = this.props.position.y
    return ret
  }

  // FIRST UNBOARDING
  get needToAppear() {
    return this.block.key === 'code' && !this.$store.getters['app/getFirstUnboardingDone']
  }
  // END FIRST UNBOARDING

}
