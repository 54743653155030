import Vuex from 'vuex'

import app from './modules/app'
import blocks from './modules/blocks'
import platforms from './modules/platforms'
import elements from './modules/elements'
import environments from './modules/environments'
import fields from './modules/fields'
import blocksAPI from './modules/blocksAPI'
import projects from './modules/projects'
import errorsValidation from './modules/errorsValidation'
import deployment from './modules/deployment'
import user from './modules/user'
import selectFields from './modules/selectFields'

export default new Vuex.Store({
  modules: {
    app: {
      namespaced: true,
      ...app
    },
    blocks: {
      namespaced: true,
      ...blocks
    },
    platforms: {
      namespaced: true,
      ...platforms
    },
    elements: {
      namespaced: true,
      ...elements
    },
    environments: {
      namespaced: true,
      ...environments
    },
    fields: {
      namespaced: true,
      ...fields
    },
    blocksAPI: {
      namespaced: true,
      ...blocksAPI
    },
    projects: {
      namespaced: true,
      ...projects
    },
    errorsValidation: {
      namespaced: true,
      ...errorsValidation
    },
    deployment: {
      namespaced: true,
      ...deployment
    },
    user: {
      namespaced: true,
      ...user
    },
    selectFields: {
      namespaced: true,
      ...selectFields
    },
  }
})