
import CustomButton from '@/components/UIElements/CustomButton.vue';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import TextBetweenLines from '@/components/UIElements/TextBetweenLines.vue';
import LoginSocialButton from '@/components/Actions/LoginSocialButton.vue';
import { Vue, Options, prop } from 'vue-class-component';
import API from '@/api/wrapper'

class Props {
  withPassword?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    CustomButton,
    CustomTextInput,
    LoginSocialButton,
    TextBetweenLines
  },
})
export default class LoginBar extends Vue.with(Props) {
  login = ''
  password = '' 
  loading = false
  btnText = 'Log in / Sign up'
  errors = {
    login: "",
    password: ""
  }
  buttonState = ''
  displayGenericError = false

  mounted() {
    API.session.getCSRF().then(() => {
      API.user.getMe()
      .then(() => {
        this.$router.push('flow')
      })
    })
  }

  doLogin () {
    if(this.withPassword) {
      this.loading = true
      this.btnText = ""
      this.displayGenericError = false
      API.session.login(this.login, this.password)
      .then(() => {
        this.loading = false
        this.buttonState = this.$enums.ButtonStates.Success
        this.$router.push('flow')
      })
      .catch((err:any) => {
        this.buttonState = this.$enums.ButtonStates.Danger
        this.loading = false
        setTimeout(() => {
          this.buttonState = ''
          this.btnText = 'Log in'
        },2000)

        this.errors.login = err.response.data?.meta?.login ? err.response.data.meta.login.join(' ') : ''
        this.errors.password = err.response.data?.meta?.password ? err.response.data.meta.password.join(' ') : ''
        if(err.response.status === 403) {
          this.displayGenericError = true
        }
      })
    } else {
      this.$router.push({name:'register', query: {email: this.login}})
    }
  }

}
