import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, { class: "fransys-signature" }, {
    default: _withCtx(() => [
      _createVNode(_component_Logo, {
        logo: "fransys",
        class: "mx-0 ml-auto my-auto"
      }),
      _createVNode(_component_Logo, {
        logo: "fransys-txt",
        class: "mx-0 ml-3 my-auto"
      }),
      _createVNode(_component_Badge, {
        state: _ctx.$enums.BadgeStates.Neutral,
        outlined: false,
        class: "mx-0 ml-4 mr-auto my-auto"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Alpha")
        ]),
        _: 1
      }, 8, ["state"])
    ]),
    _: 1
  }))
}