
import { Vue, prop, Options } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'
import List, { ListItem } from '@/components/UIElements/List.vue';

import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import { Watch } from 'vue-property-decorator';
import { nextTick } from 'vue';

export class Props {
  items?: ListItem[] = prop({
    required: false,
  });
  modelValue?: ListItem = prop({
    required: false,
  });
  displaySearch?: boolean = prop({
    required: false,
  });
  loading?: boolean = prop({
    required: false,
  });
  width?: string = prop({
    required: false,
  });
  top?: string = prop({
    required: false,
  });
  left?: string = prop({
    required: false,
  });
  bottom?: string = prop({
    required: false,
  });
  right?: string = prop({
    required: false,
  });
  show: boolean = prop({
    required: true,
  });
  query?: string = prop({
    required: false,
  });
  maxContent?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon,
    List,
    CustomTextInput,
  },
})
export default class DropdownMenu extends Vue.with(Props) {
  opened = false;
  domRect?:DOMRect; 
  maxHeight = 0

  @Watch('show')
  onShowChange() {
    this.calculateMaxHeight()
  }

  calculateMaxHeight() {
    nextTick(() => {
      const DOMelem = this.$refs.dropdownMenu as HTMLElement
      if(DOMelem) {
        this.domRect = DOMelem.getBoundingClientRect()
        this.maxHeight = (window.innerHeight - 92 ) - this.domRect.top
      }
    })
    
  }

  onOutsideClick() {
    this.$emit('update:show',false)
  }

  onSelect(item:ListItem) {
    this.opened = false
    this.$emit('update:modelValue', item)
  }
}



