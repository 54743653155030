
import { Vue, Options, prop, WithDefault } from 'vue-class-component'
import HeightDeployer from '@/components/UIElements/HeightDeployer.vue'
import Icon from '@/components/UIElements/Icon.vue'
import { APIFieldBox, APIFieldValue } from '@/typesAPI'
import { Watch } from 'vue-property-decorator';
import { nextTick } from 'vue';

class Props {
  title: string = prop({
    required: true,
  });
  forceDeployed?: boolean = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  deployed: boolean = prop({
    required: true,
  });
  vertical?: boolean = prop({
    required: false,
  });
  box: APIFieldBox = prop({
    required: false,
  });
}

@Options({
  components: {
    HeightDeployer,
    Icon
  },
})
export default class FieldBox extends Vue.with(Props) {

  maxWidthSoloValue = 370
  isLongValue = false

  get isCollapsible(): boolean {
    return !!this.box?.attributes.isCollapsible
  }

  get computedDeployed():boolean {
    if(this.box) {
      if(!this.isInvisible) {
        return this.openedBoxId === this.box.id
      } else {
        return this.isCollapsible ? !!this.deployed : true
      }
    }
    return !!this.deployed
  }

  get openedBoxId():string {
    return this.$route.params.boxId as string
    // return this.$store.getters['fields/getOpenedBoxId']
  }

  @Watch('openedBoxId')
  onOpenedBoxIdChange () {
    this.$emit('update:deployed', this.computedDeployed)
  }

  mounted() {
    this.$emit('update:deployed', false)
    setTimeout(() => {
      this.$emit('update:deployed', this.computedDeployed)
      this.checkLongValue()
    },100)
  }

  @Watch('computedDeployed')
  onRefRowValueChange(newVal:boolean, oldVal:boolean) {
    if(!newVal && oldVal) {
      nextTick(() => {
        this.checkLongValue()
      })
    }
  }

  @Watch('fieldsValues', {deep:true})
  onFieldsValuesChange(newVal:boolean, oldVal:boolean) {
    if(!this.deployed) {
      setTimeout(() => {
        this.checkLongValue()
      },50)
    }
  }

  get fieldsValues ():APIFieldValue[] {
    return this.$store.getters['fields/getListValues']
  }

  get isInvisible(): boolean {
    return this.box?.attributes?.isInvisible
  }

  checkLongValue() {
    if(this.box?.childsByPriority[0]?.attributes?.key === 'url') {
      this.isLongValue = true;
      return;
    }

    if(!this.vertical) {
      this.isLongValue = false
      if(this.$refs.rowValue && this.$refs.rowTitle) {
        // @ts-ignore
        if(this.$refs.rowValue.$el.getBoundingClientRect().width + this.$refs.rowTitle.$el.getBoundingClientRect().width > this.maxWidthSoloValue) {
          this.isLongValue = true
        } else {
          this.isLongValue = false
        }
      }
    }
  }

  deploy() {
    if(!this.disabled) {
      if(this.computedDeployed) {
        this.collapse()
      } else {
        this.expand()
      }
    }
  }

  collapse() {
    if(this.isCollapsible) {
      if(this.box && !this.isInvisible) {
        this.$router.push({
          name: 'flowBlock',
          params: {
            ...this.$route.params,
          }
        });
      }

      this.$emit('update:deployed', false)
    }
  }

  expand() {
    if(this.box && !this.isInvisible) {
      this.$router.push({
        name: 'flowBlockAndBox',
        params: {
          ...this.$route.params,
          boxId: this.box.id
        }
      });
    }
    this.$emit('update:deployed', true)
  }

}
