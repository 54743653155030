import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import FlowView from '@/views/FlowView.vue'
import HomeView from '@/views/HomeView.vue'
import RegisterView from '@/views/RegisterView.vue'
import TokenLoginView from '@/views/TokenLoginView.vue'
import SocialAccountCallbackView from '@/views/SocialAccountCallbackView.vue'
import LoginSocialCallbackView from '@/views/LoginSocialCallbackView.vue'
import AdminView from '@/views/AdminView.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'login' }
  },
  {
    path: '/login',
    name: 'login',
    component: HomeView,
    props: { needAuth: false }
  },
  {
    path: '/login/:socialProvider/callback',
    name: 'loginSocialCallback',
    component: LoginSocialCallbackView,
    props: { needAuth: false }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    props: { needAuth: false }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    props: { needAuth: false }
  },
  {
    path: '/checkout/cancelled',
    name: 'checkoutCancel',
    redirect: { name: 'flow' },
  },
  {
    path: '/checkout/success',
    name: 'checkoutSuccess',
    redirect: to => {
      localStorage.setItem("paymentPending", "1");
      return 'flow';
    },
  },
  {
    path: '/token',
    name: 'token',
    component: TokenLoginView,
    props: { needAuth: false }
  },
  {
    path: '/flow',
    name: 'flow',
    component: FlowView,
    props: { needAuth: true }
  },
  {
    path: '/flow/block/:blockId/tab/:tabId',
    name: 'flowBlock',
    component: FlowView,
    props: { needAuth: true }
  },
  {
    path: '/flow/block/:blockId/tab/:tabId/box/:boxId',
    name: 'flowBlockAndBox',
    component: FlowView,
    props: { needAuth: true }
  },
  {
    path: '/socialAccounts/:socialProvider/callback',
    name: 'socialAccountCallback',
    component: SocialAccountCallbackView,
    props: { needAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
