import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7744e9d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "link-detail-top" }
const _hoisted_2 = { class: "link-detail-right" }
const _hoisted_3 = { class: "link-detail-bottom" }
const _hoisted_4 = { class: "btn-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!

  return (_ctx.link)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["link-detail-container", {'opened': _ctx.isOpened, transparent: _ctx.isModalTransparent}]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["link-detail bar", {ready:_ctx.isPlaced}]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
          ref: "linkDetailDOM",
          style: _normalizeStyle('top:'+ _ctx.top + 'px; left:' + _ctx.left + 'px')
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["arrow", {
          top: _ctx.position === _ctx.POSITION.BOTTOM,
          bottom: _ctx.position === _ctx.POSITION.TOP,
          left: _ctx.position === _ctx.POSITION.RIGHT,
          right: _ctx.position === _ctx.POSITION.LEFT
        }]),
            style: _normalizeStyle('top:'+ _ctx.arrowTop + 'px; left:' + _ctx.arrowLeft + 'px;')
          }, "   ", 6),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_Spinner, {
                    key: 0,
                    class: "ma-auto"
                  }))
                : (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    class: "pa-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "link-detail-title" }, {
                        default: _withCtx(() => [
                          (_ctx.variablesOverrides.length)
                            ? (_openBlock(), _createBlock(_component_v_col, {
                                key: 0,
                                class: "pa-0"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Connection variable ")
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_v_col, {
                            align: "end",
                            class: "pa-0"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_CustomButton, {
                                class: "ml-auto",
                                level: _ctx.$enums.ButtonLevels.SmallIcon,
                                icon: "x",
                                onClick: _ctx.onClose
                              }, null, 8, ["level", "onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.variablesOverrides.length)
                        ? (_openBlock(), _createBlock(_component_v_row, {
                            key: 0,
                            class: "link-detail-content"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { class: "pa-0" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, { class: "link-detail-variable" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Original variable name ")
                                    ]),
                                    _: 1
                                  }),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.variablesOverrides, (variableOverride) => {
                                    return (_openBlock(), _createBlock(_component_v_row, {
                                      class: "link-detail-variable",
                                      key: variableOverride.id+'-original_name'
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_CustomTextInput, {
                                          modelValue: variableOverride.attributes.original_name,
                                          "onUpdate:modelValue": ($event: any) => ((variableOverride.attributes.original_name) = $event),
                                          disabled: true,
                                          disabledBox: true
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { class: "pa-0 pl-4" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, { class: "link-detail-variable" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Override variable name ")
                                    ]),
                                    _: 1
                                  }),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.variablesOverrides, (variableOverride) => {
                                    return (_openBlock(), _createBlock(_component_v_row, {
                                      class: "link-detail-variable",
                                      key: variableOverride.id+'-name'
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_CustomTextInput, {
                                          modelValue: variableOverride.attributes.name,
                                          "onUpdate:modelValue": ($event: any) => ((variableOverride.attributes.name) = $event),
                                          onBlur: ($event: any) => (_ctx.editOverride($event, variableOverride.id)),
                                          error: _ctx.isInError(variableOverride.id)
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onBlur", "error"])
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_CustomButton, {
                level: _ctx.$enums.ButtonLevels.Tertiary,
                icon: "trash",
                text: "Delete link",
                class: "delete-btn footer-btn",
                onClick: _ctx.deleteLink
              }, null, 8, ["level", "onClick"])
            ])
          ])
        ], 6)
      ], 2))
    : _createCommentVNode("", true)
}