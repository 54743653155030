
import { Vue, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import DeployButton from '@/components/Deployment/DeployButton.vue'
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import ListElement from '@/components/UIElements/ListElement.vue';
import Badge from '@/components/UIElements/Badge.vue';
import HeightDeployer from '@/components/UIElements/HeightDeployer.vue';
import Checkbox from '@/components/UIElements/Checkbox.vue';
import SubscriptionStartTrialModal from '@/components/Subscription/SubscriptionStartTrialModal.vue';
import SubscriptionTrialOverModal from '@/components/Subscription/SubscriptionTrialOverModal.vue';
import SubscriptionExpiredModal from '@/components/Subscription/SubscriptionExpiredModal.vue';

import { APIBlock, APIDeploymentPlan, APIDeploymentVersion, APIDeploymentLog, APIDeploymentRun, APIDeploymentSubject } from '@/typesAPI'
import API from '@/api/wrapper'
import { Watch } from 'vue-property-decorator';


@Options({
  components: {
    CustomButton,
    DeployButton,
    Icon,
    Logo,
    Spinner,
    ListElement,
    SubscriptionStartTrialModal,
    SubscriptionTrialOverModal,
    SubscriptionExpiredModal,
    Badge,
    HeightDeployer,
    Checkbox
  },
})
export default class ValidationErrorsModal extends Vue {
  
  isLoading = false
  isLoadingLog = false
  hideClusterSubtext = false

  planExpanded:APIDeploymentPlan | null = null

  mounted(): void {
    API.deployment.getVersions(this.$store.getters['projects/getCurrentProjectId'])
    .then((deployementVersions: APIDeploymentVersion[]) => {
      if(deployementVersions) {
        this.$store.dispatch('deployment/setDeploymentVersions', deployementVersions)
        deployementVersions.forEach((deployementVersion:APIDeploymentVersion) => {
          if(deployementVersion.attributes.status === this.$enums.DeploymentState.Draft || deployementVersion.attributes.status === this.$enums.DeploymentState.Running) {
            API.deployment.getDeploymentPlans(deployementVersion.id)
              .then ((value: { deploymentPlans: APIDeploymentPlan[]; subjects:APIDeploymentSubject[];}) => {
                if (deployementVersion.attributes.status ===  this.$enums.DeploymentState.Running) {
                  this.$store.dispatch('deployment/setModalIsOpen', true)
                }
                this.$store.dispatch('deployment/addDeploymentPlans', value.deploymentPlans)

                if(value.subjects.length) {
                  value.subjects.forEach((subject) => {
                    this.$store.dispatch('deployment/addDeploymentSubject', subject)
                  })
                }

              })
          }
        })
        
      }
    })
  }

  getTimeToDisplay(time:string):string {
    const date = new Date(time)
    return (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) + ':' + (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) + ':' + (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())
  }

  toggleReduce () {
    this.$store.dispatch('deployment/setModalIsReduced', !this.isReduced)
    this.$store.dispatch('deployment/setModalIsDraftView', false)
    this.$store.dispatch('deployment/setForceDeployCompletedState', false)

    // this.$store.dispatch('deployment/setModalIsOpen', !this.open)
    // if(!this.isDeployFinished) {
    //   if(!this.isReduced && this.plans.length === 0) {
    //     this.$store.dispatch('deployment/setDeployingState', this.$enums.DeploymentState.NotStarted)
    //     this.$store.dispatch('deployment/setModalIsOpen', false)
    //   } else {
    //     this.$store.dispatch('deployment/setModalIsReduced', !this.isReduced)
    //   }
    // }
  }

  closeExpandPlan() {
    this.planExpanded = null
  }

  toggleExpandPlan (deploymentPlan:APIDeploymentPlan) {
    this.planExpanded = deploymentPlan
    this.loadPlanLogs(deploymentPlan.id)
  }

  loadPlanLogs(deploymentPlanId:string) {
    this.isLoadingLog = true
    API.deployment.getLogs(deploymentPlanId)
    .then((logs:APIDeploymentLog[]) => {
      this.$store.dispatch('deployment/setDeploymentLogs', {deploymentLogs: logs, planId: deploymentPlanId})
      this.isLoadingLog = false
      setTimeout(() => {
        const htmlElem = document.getElementsByClassName("log-window")[0]
        htmlElem.scrollTo({
          top: htmlElem.scrollHeight,
          left: 0,
          behavior: "smooth",
        })
      })
    })
  }

  getPlanLogs(deploymentPlanId:string) {
    return this.$store.getters['deployment/getDeploymentLogsByPlanId'](deploymentPlanId)
  }


  getRollingBackPlanFromPlan(deploymentPlan:APIDeploymentPlan):APIDeploymentPlan | null {
    if(!this.isRollingBack) {
      return null
    }
    const ret = this.plansRollback.find((plan:APIDeploymentPlan) => plan.relationships.subject.data.id === deploymentPlan.relationships.subject.data.id)
    return ret && ret.attributes.operation !== this.$enums.DeploymentPlanOperations.NOOP ? ret : null
  }

  deploymentPlanIcon(deploymentPlan:APIDeploymentPlan):string {
    const ret:{[key:string]: string} = {}

    ret[this.$enums.DeploymentRunState.PENDING] = 'clock'
    ret[this.$enums.DeploymentRunState.STARTING] = 'spinner'
    ret[this.$enums.DeploymentRunState.RUNNING] = 'spinner'
    ret[this.$enums.DeploymentRunState.WAITING_FOR_CALLBACK] = 'spinner'
    ret[this.$enums.DeploymentRunState.SUCCEEDING] = 'spinner'
    ret[this.$enums.DeploymentRunState.SUCCEEDED] = 'check-circle'
    ret[this.$enums.DeploymentRunState.RETRIED] = 'cancel-circle'
    ret[this.$enums.DeploymentRunState.FAILED] = 'cancel-circle'
    ret[this.$enums.DeploymentRunState.ROLLED_BACK] = 'cancel-circle'
    ret[this.$enums.DeploymentRunState.ABANDONED] = 'block'

    return ret[deploymentPlan?.attributes?.status]
  }

  deploymentPlanBadgeState(deploymentPlan:APIDeploymentPlan):string {
    if(deploymentPlan.attributes.operation === this.$enums.DeploymentPlanOperations.NOOP) {
      return this.$enums.BadgeStates.Neutral
    }

    const ret:{[key:string]: string} = {}

    ret[this.$enums.DeploymentRunState.PENDING] = this.$enums.BadgeStates.Neutral
    ret[this.$enums.DeploymentRunState.STARTING] = this.$enums.BadgeStates.Neutral
    ret[this.$enums.DeploymentRunState.RUNNING] = this.$enums.BadgeStates.Neutral
    ret[this.$enums.DeploymentRunState.WAITING_FOR_CALLBACK] = this.$enums.BadgeStates.Neutral
    ret[this.$enums.DeploymentRunState.SUCCEEDING] = this.$enums.BadgeStates.Neutral
    ret[this.$enums.DeploymentRunState.SUCCEEDED] = this.$enums.BadgeStates.Success
    ret[this.$enums.DeploymentRunState.RETRIED] = this.$enums.BadgeStates.Neutral
    ret[this.$enums.DeploymentRunState.FAILED] = this.$enums.BadgeStates.Danger
    ret[this.$enums.DeploymentRunState.ROLLED_BACK] = this.$enums.BadgeStates.Danger
    ret[this.$enums.DeploymentRunState.ABANDONED] = this.$enums.BadgeStates.Neutral

    return ret[deploymentPlan?.attributes?.status]
  }

  deploymentPlanBadgeLabel(deploymentPlan:APIDeploymentPlan):string {
    const labels:{[key:string]: string} = {}
  
    labels[this.$enums.DeploymentPlanOperations.NOOP] = 'No Changes'
    labels[this.$enums.DeploymentPlanOperations.CREATION] = 'New'
    labels[this.$enums.DeploymentPlanOperations.UPDATE] = 'Update'
    labels[this.$enums.DeploymentPlanOperations.DESTRUCTION] = 'Delete'

    return labels[deploymentPlan.attributes.operation]
  }


  textFromDeploymentPlan(deploymentPlan:APIDeploymentPlan) {
    switch(deploymentPlan?.relationships?.subject?.data.type) {
      case 'kubernetesClusters' :
        return 'Kubernetes cluster'
      case 'blocks' :
        return this.getAPIBlockNameFromID(deploymentPlan?.relationships?.subject?.data.id)
      default: 
        return ''
    }
  }

  getAPIBlockNameFromID(id:string): string {

    const apiBlock:APIBlock =  this.listSubjects.find((subject:APIDeploymentSubject) => {
      return subject.id === id
    }) as APIBlock
    if(!apiBlock?.attributes) {
      return ""
    }
    return apiBlock?.attributes?.name ? apiBlock.attributes.name : apiBlock?.attributes?.default_name
  }

  getLogoFromDeploymentPlan(deploymentPlan:APIDeploymentPlan): string {
    if(deploymentPlan?.relationships?.subject?.data.type === 'blocks') {
      const apiBlock:APIBlock = this.listSubjects.find((subject:APIDeploymentSubject) => {
        return subject.id === deploymentPlan.relationships.subject.data.id
      }) as APIBlock
      return apiBlock && this.$enums.EnginesLogos[apiBlock.attributes.default_name] ? this.$enums.EnginesLogos[apiBlock.attributes.default_name] : ''
    } else if (deploymentPlan?.relationships?.subject?.data.type === 'kubernetesClusters') {
      return this.$enums.EnginesLogos['kubernetes']
    }

    return ''
  }

  updateDeploymentPlanIncludeInVersion(deploymentPlan:APIDeploymentPlan, newState:boolean) {
    API.deployment.includeDeploymentPlanInVersion(deploymentPlan.id, newState)
    .then((newPlan:APIDeploymentPlan) => {
      this.$store.dispatch('deployment/editDeploymentPlan', newPlan)
    })
  }

  openDraftView() {
    this.$store.dispatch('deployment/setModalIsDraftView', true)
    this.$store.dispatch('deployment/setModalIsOpen', true)
    this.$store.dispatch('deployment/setForceDeployCompletedState', false)
  }

  getDeploymentPlansByVersionId(versionId:string) {
    return this.$store.getters['deployment/getDeploymentPlansByVersionId'](versionId)
  }

  get deploymentPlansDraft() {
    if(this.draftVersion) {
      return this.getDeploymentPlansByVersionId(this.draftVersion.id)
    }
    return []
  }

  get draftVersion():APIDeploymentVersion | undefined {
    return this.$store.getters['deployment/getDraftVersion']
  }


  toggleClusterSubtext() {
    // this.hideClusterSubtext = !this.hideClusterSubtext
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }

  get open ():boolean {
    return this.$store.getters['deployment/getModalIsOpen']
  }

  get isReduced ():boolean {
    return this.$store.getters['deployment/getModalIsReduced']
  }

  get plans ():APIDeploymentPlan[] {
    return this.$store.getters['deployment/getRunningOrCompletedDeploymentPlans']
  }
  get plansRollback ():APIDeploymentPlan[] {
    return this.$store.getters['deployment/getDeploymentPlansRollback']
  }

  get isRollingBack ():boolean {
    return !!this.plansRollback.length
  }

  get listSubjects ():APIDeploymentSubject[] {
    return this.$store.getters['deployment/getListSubjects']
  }

  get deployingState (): string {
    return this.$store.getters['deployment/getDeployingState']
  }

  get deploying (): boolean {
    return this.deployingState === this.$enums.DeploymentState.Running
  }

  get isDeployFinished (): boolean {
    return this.deployingState === this.$enums.DeploymentState.Completed
  }

  get titleText (): string {
    return this.isDeployFinished ? 'Deployment complete' : 'Deployment in progress'
  }

  get clusterSubtext (): string {
    return 'Deploying a cluster for the first time can take up to 10 minutes.<br/> You can close this panel.'
  }

  get isDraftView (): boolean {
    return this.$store.getters['deployment/getModalIsDraftView']
  }

  get getShouldDisablePublish (): boolean {
    let ret = true

    if(this.draftVersion) {
      const plans = this.getDeploymentPlansByVersionId(this.draftVersion.id)
      plans?.forEach((plan:APIDeploymentPlan) => {
        if(plan.attributes.include_in_version === true) {
          ret = false
        }
      })
    }
    
    return ret
  }

  

  


  get draftViewText (): string {
    let ret = "Deploy all changes"

    if(this.draftVersion) {
      const plans = this.getDeploymentPlansByVersionId(this.draftVersion.id)
      plans?.forEach((plan:APIDeploymentPlan) => {
        if(plan.attributes.include_in_version === false) {
          ret = "Deploy selection"
        }
      })
    }
    
    return ret
  }
  

  @Watch('isDeployFinished')
  onisDeployFinishedChange(val:boolean) {
    if(val && this.isDraftView) {
      setTimeout(() => {
        this.$store.dispatch('deployment/setForceDeployCompletedState', false)
      },3000)
    }
  }


  
}
