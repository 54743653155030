
import { Options, Vue } from 'vue-class-component';
import FlowChart from '@/components/FlowChart.vue'; 
import PanelsLayout from '@/components/Panels/PanelsLayout.vue';
import NodeDetail from '@/components/Details/NodeDetail.vue';
import LinkDetail from '@/components/Details/LinkDetailV2.vue';
import Spinner from '@/components/UIElements/Spinner.vue';
import API from '@/api/wrapper'
import { Block, ElementNode } from '@/types';
import { Watch } from 'vue-property-decorator';
import ValidationErrorsModal from '@/components/ValidationErrors/ValidationErrorsModal.vue'
import DeploymentModal from '@/components/Deployment/DeploymentModal.vue'
import SocketsManager from '@/components/Sockets/SocketsManager.vue';
import { APIBlockCategory, APIUser, APISocialAccount } from '@/typesAPI';
import SubscriptionStatusMessage from '@/components/Subscription/SubscriptionStatusMessage.vue';
import ModalCGU from '@/components/Modals/ModalCGU.vue';
import KC from '@/components/KC/KC.vue';

@Options({
  components: {
    FlowChart,
    SocketsManager,
    PanelsLayout,
    Spinner,
    NodeDetail,
    LinkDetail,
    ValidationErrorsModal,
    DeploymentModal,
    SubscriptionStatusMessage,
    ModalCGU,
    KC
  },
})
export default class FlowView extends Vue {
  loadingIndex = true
  loadingCategories = true
  mounted(): void {
    this.$store.dispatch('app/setFirstUnboardingDone', !!localStorage.getItem('isFirstUnboardDone'))
    API.user.getMe()
      .then((res:{user:APIUser, socialAccounts:APISocialAccount[]}) => {
        this.$store.dispatch('user/setUser', res.user)
        res.socialAccounts.forEach((socialAccount:APISocialAccount) => {
          this.$store.dispatch('user/setSocialAccount', {provider: socialAccount.attributes.provider, socialAccount: socialAccount})
        })
        this.$store.dispatch('projects/setCurrentProjectId', res.user.meta.project_id)
        this.$store.dispatch('errorsValidation/loadErrors')
        API.blocks.getCategories()
          .then(({blockCategories, definitions}) => {
            const blocks:Block[] = []
            blockCategories.forEach((blockCategory:APIBlockCategory) => {
              const block:Block = {
                ...blockCategory.attributes,
                id: blockCategory.id,
                preselectedFieldDefinitionId: blockCategory?.relationships.preselect_field_definition.data.id
              }
              blocks.push(block)
            })
            this.$store.dispatch('blocks/setBlockList', blocks)
            this.$store.dispatch('fields/addNodeFieldDefinitions', definitions)


            API.blocks.getIndex(this.$store.getters['projects/getCurrentProjectId'])
            .then((result) => {
              this.$store.dispatch('blocksAPI/setBlockList', result.blocks)
              this.$store.dispatch('elements/setNodesFromAPIBlocks', result.blocks)
              this.$store.dispatch('elements/setLinksFromAPIConnections', result.connections)
              this.$store.dispatch('fields/setFieldsValue', result.fieldsValue)
              this.$store.dispatch('fields/addNodeFieldDefinitions', result.fieldsDefinition)
              this.$store.dispatch('fields/setFieldTabs', result.fieldsTabs)
              this.$store.dispatch('fields/setFieldBoxes', result.fieldsBoxes)
            })
            .finally(() => {
              this.handleURL()
              this.loadingIndex = false
            })
          })
          .finally(() => {
            this.loadingCategories = false
          })
          
      })
      .catch(() => {
        this.$router.push({name: 'login'})
      })
  }

  get computedLoading(): boolean {
    return this.loadingIndex || this.loadingCategories
  }

  get CGUAccepted(): boolean {
    return this.$store.getters['user/getUser']?.attributes?.terms_of_use_accepted_at !== null
  }

  @Watch('computedLoading')
  onLoadingEnd(newValue:boolean) {
    if(!newValue) {
      setTimeout(() => {
        localStorage.setItem('isFirstUnboardDone', 'true')
        this.$store.dispatch('app/setFirstUnboardingDone', true)
      },1000)
    }
  }

  handleURL () {
    if(this.$route.params.blockId) {
      // Open Block
      this.$store.getters['elements/getNodes'].forEach((node:ElementNode) => {
        if(node.extra.blockAPIID === this.$route.params.blockId) {
          this.$store.dispatch('elements/setDetailOpenElement', node)
        }
      })
    }
  }

  @Watch('$route.params')
  onQueryChange() {
    this.handleURL()
  }
}
