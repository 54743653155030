import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["logo-bar bar py-2 d-flex", {collapsed:_ctx.collapsed}])
  }, [
    _createVNode(_component_v_col, { class: "ma-auto pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "pa-0 ma-0 d-flex" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "logo-container pa-0 ma-auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_Logo, { logo: "fransys" })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}