import { APIBlock } from "@/typesAPI"
import helpers from '@/utils/helpers'

type State = {
  list: APIBlock[]
}

const state: State = {
  list : [],
}


const getters = {   
  getList: (state:State) => {
    return state.list
  },
  getByID: (state:State) => {
    return (id:string) => {
      return state.list.find(blockAPI => blockAPI.id === id)
    }
  },
  getByIDCategory: (state:State) => {
    return (id:string) => {
      return state.list.find(blockAPI => blockAPI?.relationships?.category?.data.id === id)
    }
  },      
}

const mutations = {
  EDIT_BLOCKAPI: (state:State, blockAPI:APIBlock) => {
    const index = state.list.findIndex((oldBlockAPI) => oldBlockAPI.id === blockAPI.id)
    if(index !== -1 ) {
      if(helpers.ressources.isNewerThan(blockAPI, state.list[index])) {
        state.list[index] = blockAPI
      }
    } else {
      state.list.push(blockAPI)
    }
  },
  SET_LIST: (state:State, blocksAPI:APIBlock[]) => {
    state.list = blocksAPI
  },
}

const actions = {
  addNode: (context:any, blockAPI:APIBlock) => {
    context.commit('EDIT_BLOCKAPI', blockAPI)
  },
  editBlock: (context:any, blockAPI:APIBlock) => {
    context.commit('EDIT_BLOCKAPI', blockAPI)
  },
  setBlockList: (context:any, blocksAPI:APIBlock[]) => {
    context.commit('SET_LIST', blocksAPI)
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}