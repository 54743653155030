
import { Vue, Options } from 'vue-class-component'
import { Element } from '@/types'
import Icon from '@/components/UIElements/Icon.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import { Watch } from 'vue-property-decorator';
import {APIBlockEnvironmentVariableOverrides, APIErrorValidation } from '@/typesAPI';

import API from '@/api/wrapper'
import { nextTick } from 'vue'


enum POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

@Options({
  components: {
    Icon,
    CustomButton,
    CustomTextInput,
    Spinner
  },
})
export default class LinkDetailV2 extends Vue {
  loading = false
  variablesOverrides:APIBlockEnvironmentVariableOverrides[] = []
  POSITION = POSITION
  top = 0;
  left = 0;
  width = 464;
  arrowTop = 0;
  arrowLeft = 0;
  arrowWidth = 10;
  isPlaced = false
  position = POSITION.BOTTOM

  onClose() {
    this.$store.dispatch('elements/setSelectedLink')
    this.$store.dispatch('elements/setDetailOpenLink')
  }

  placePopover () {
      const edgeButtonRect:DOMRect  = document.querySelector('[data-id="'+this.link.id + '"] .edge-button')?.getBoundingClientRect() as DOMRect 
      let linkDetailRect:DOMRect = new DOMRect()

      if(this.$refs.linkDetailDOM) {
        linkDetailRect = (this.$refs.linkDetailDOM as HTMLElement).getBoundingClientRect()
      }
      switch(this.position) {
        case POSITION.BOTTOM :
          // Put on Buttom 
          this.top = edgeButtonRect.top + edgeButtonRect.height
          this.left = edgeButtonRect.left - (this.width/2)
          this.arrowLeft = (this.width/2) + this.arrowWidth - 3
          this.arrowTop = 0-this.arrowWidth
        break;
        case POSITION.TOP :
          // Put on Top 
          this.top = edgeButtonRect.top + edgeButtonRect.height - (linkDetailRect.height + 35)
          this.left = edgeButtonRect.left - (this.width/2)
          this.arrowLeft = (this.width/2) + this.arrowWidth - 3
          this.arrowTop = linkDetailRect.height - 2
        break;
        case POSITION.LEFT :
          // Put on Left 
          this.top = edgeButtonRect.top + edgeButtonRect.height - (linkDetailRect.height/2)
          this.left = edgeButtonRect.left - this.width
          this.arrowLeft = this.width - 2
          this.arrowTop = (linkDetailRect.height/2) - (this.arrowWidth * 3)
        break;
        case POSITION.RIGHT :
          // Put on Right 
          this.top = edgeButtonRect.top + edgeButtonRect.height - (linkDetailRect.height/2)
          this.left = edgeButtonRect.left + edgeButtonRect.width
          this.arrowLeft = 0-this.arrowWidth
          this.arrowTop = (linkDetailRect.height/2) - (this.arrowWidth * 3)
        break;
        default:
          // Put on Buttom 
          this.top = edgeButtonRect.top + edgeButtonRect.height
          this.left = edgeButtonRect.left - (this.width/2)
          this.arrowLeft = (this.width/2) - this.arrowWidth
          this.arrowTop = 0-this.arrowWidth
        break;
      }
      this.isPlaced = true
    
  }

  deleteLink() {
    this.$store.dispatch('elements/removeLink', this.link.id)
    this.$store.dispatch('elements/setSelectedLink')
    this.$store.dispatch('elements/setDetailOpenLink')
  }

  loadVariablesOverrides() {
    this.loading = true
    this.isPlaced = false
    nextTick(() => {
      this.placePopover()
    })

    API.environmentVariables.getOverride(this.link.id)
    .then((datas:APIBlockEnvironmentVariableOverrides[]) => {
      this.variablesOverrides = datas
    })
    .finally(() => {
      this.loading = false
      nextTick(() => {
        this.placePopover()
      })
    })
  }

  editOverride(value:string, idOverride:string) {
    API.environmentVariables.editOverride(idOverride, value)
  }

  get link ():Element {
    return this.$store.getters['elements/getDetailOpenLink']
  }

  get isOpened () {
    return !!this.link
  }

  @Watch('link')
  onLinkChange() {
    if(this.link) {
      this.loadVariablesOverrides()
    }
  }

  isInError(id:string):boolean {
    return !!this.errorsValidation.find((error: APIErrorValidation) => error.meta.additionalContext?.overrides?.includes(id))
  }

  get errorsValidation (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getErrors'].filter((error:APIErrorValidation) => error.relationships.source.data.id === this.link.id)
  }

  get warningsValidation (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getWarnings'].filter((error:APIErrorValidation) => error.relationships.source.data.id === this.link.id)
  }

  get isModalTransparent ():boolean {
    return this.$store.getters['errorsValidation/getModalTransparency']
  }

}
