
import { Vue } from 'vue-class-component';
import Echo from "laravel-echo";
import API from '@/api/wrapper';
import getEnv from '@/utils/env';
import { APIBlock, APIDeploymentLog, APIDeploymentPlan, APIDeploymentSubject, APIDeploymentVersion, APIFieldValue } from '@/typesAPI';

export default class SocketsManager extends Vue {
  echo?:Echo   

  mounted(): void {
    this.connectSocket()
    this.startListening()
  }


  connectSocket() {
    //@ts-ignore
    window.Pusher = require('pusher-js');

    this.echo =  new Echo({
      broadcaster: 'pusher',
      key: 'app-key',
      wsHost: getEnv('VUE_APP_SOCKET_URL'),
      wsPort: 80,
      wssPort: 443,
      forceTLS: false,
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      authorizer: (channel:any, options:any) => {
        return {
          authorize: (socketId:any, callback:any) => {
            API.sockets.auth(callback, {
              socket_id: socketId,
              channel_name: channel.name
            })
          }
        };
      },
    });

  }

  startListening() {
    if(this.echo) {

      this.echo
        .channel('private-users.'+ this.$store.getters['user/getUser'].id)
        // User
        .listen('.users.updated', (e:any) => {
          this.$helpers.console.log('.user.updated' , e)
          this.$store.dispatch('user/setUser', e)
        })

      this.echo
        .channel('private-projects.'+ this.$store.getters['projects/getCurrentProjectId'])
        // Deployement
        .listen('.deploymentVersions.started', (deployementVersion: APIDeploymentVersion) => {
          this.$helpers.console.log('.deploymentVersions.started' , deployementVersion)

          if(deployementVersion) {
            API.deployment.getDeploymentPlans(deployementVersion.id)
            .then ((value: { deploymentPlans: APIDeploymentPlan[]; subjects:APIDeploymentSubject[];}) => {
              if (deployementVersion.attributes.status ===  this.$enums.DeploymentState.Running) {
                this.$store.dispatch('deployment/setModalIsOpen', true)
              }
              this.$store.dispatch('deployment/addDeploymentVersion', deployementVersion)
              this.$store.dispatch('deployment/addDeploymentPlans', value.deploymentPlans)
              value.subjects.forEach((subject:APIDeploymentSubject) => {
                this.$store.dispatch('deployment/addDeploymentSubject', subject)
              })
            })
          }
        })
        .listen('.deploymentPlans.logs', (e:APIDeploymentLog[]) => {
          this.$helpers.console.log('.deploymentPlans.logs' , e)
          this.$store.dispatch('deployment/addDeploymentLogs', {deploymentLogs: e, planId: e[0].relationships.plan.data.id})
        })
        .listen('.deploymentPlans.createdOrUpdated', (e:APIDeploymentPlan) => {
          this.$helpers.console.log('.deploymentPlans.createdOrUpdated' , e)
          this.$store.dispatch('deployment/editDeploymentPlan', e)
          this.$store.dispatch('deployment/addDeploymentSubject', this.$store.getters['blocksAPI/getByID'](e.relationships.subject.data.id))
        })
        .listen('.deploymentPlans.deleted', (e:APIDeploymentPlan) => {
          this.$helpers.console.log('.deploymentPlans.deleted' , e)
          this.$store.dispatch('deployment/deleteDeploymentPlan', e)
        })
        .listen('.deploymentVersions.created', (e:APIDeploymentVersion) => {
          this.$helpers.console.log('.deploymentVersions.created' , e)
          this.$store.dispatch('deployment/addDeploymentVersion', e)
        })
        .listen('.deploymentVersions.fullyCompleted', (e:any) => {
          this.$helpers.console.log('.deploymentVersions.fullyCompleted' , e)
          this.$store.dispatch('deployment/addDeploymentVersion', e)
          this.$store.dispatch('deployment/setForceDeployCompletedState', true)
        })
        // ValidationErrors
        .listen('.validationErrors.calculated', (e:any) => {
          this.$helpers.console.log('.validationErrors.calculated' , e)
          this.$store.dispatch('errorsValidation/deleteErrors', e.passing)
          this.$store.dispatch('errorsValidation/addErrors', e.failing)
        })
        // Fields
        .listen('.fields.created',  (payload:{data:APIFieldValue, included : any[]})  => {
          this.$helpers.console.log('fields.created' , payload)
          this.$emitter.emit(`fields.created.${payload.data.id}`)

          this.$store.dispatch('fields/addNodeFieldDefinition', payload.included.find((item) => item.type === "fieldDefinitions"))
          this.$store.dispatch('fields/addNodeFieldTab', payload.included.find((item) => item.type === "blockTabs"))
          this.$store.dispatch('fields/addNodeFieldBox', payload.included.find((item) => item.type === "fieldBoxes"))

          this.$store.dispatch('fields/addNodeFieldValue', payload.data)

        })
        .listen('.fields.updated', (payload:{data:APIFieldValue, included : any[]}) => {
          this.$helpers.console.log('fields.updated' , payload)
          this.$emitter.emit(`fields.updated.${payload.data.id}`)

          this.$store.dispatch('fields/addNodeFieldDefinition', payload.included.find((item) => item.type === "fieldDefinitions"))
          this.$store.dispatch('fields/addNodeFieldTab', payload.included.find((item) => item.type === "blockTabs"))
          this.$store.dispatch('fields/addNodeFieldBox', payload.included.find((item) => item.type === "fieldBoxes"))

          this.$store.dispatch('fields/addNodeFieldValue', payload.data)

        })
        .listen('.fields.deleted',  (payload:{data:APIFieldValue, included : any[]})  => {
          this.$emitter.emit(`fields.deleted.${payload.data.id}`)
          this.$helpers.console.log('fields.deleted' , payload)

          this.$store.dispatch('fields/deleteFieldValue', payload.data)
        })
    }
  }
}
