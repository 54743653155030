
import { Vue } from 'vue-class-component';
import API from '@/api/wrapper';
import { APISocialAccount, APIUser } from "@/typesAPI"

export default class SocialAccountCallbackView extends Vue {

  socialAccountProvider = ""

  mounted(): void {

    this.socialAccountProvider = this.$route.params.socialProvider.toString()

    API.user.getMe()
    .then((res:{user:APIUser, socialAccounts:APISocialAccount[]}) => {
      this.$store.dispatch('user/setUser', res.user)
      this.$store.dispatch('projects/setCurrentProjectId', res.user.meta.project_id)

      const origin = window.location.origin
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('code')
      const redirectUrl = `${origin}${window.location.pathname}?${urlParams.toString()}`


      API.social.callback(this.$store.getters['user/getUser'].id, this.socialAccountProvider, this.$route.query.code as string, redirectUrl)
      .then((socialAccount:APISocialAccount) => {
        this.$store.dispatch('user/setSocialAccount', {provider: this.socialAccountProvider, socialAccount: socialAccount})
      })
      .finally(() => {
        this.$router.replace({
          name: 'flow'+ (this.$route.query.blockId ? 'Block' : '') + (this.$route.query.boxId ? 'AndBox' : ''),
          params: {
            ...this.$route.params,
            boxId : this.$route.query.boxId?.toString(),
            blockId : this.$route.query.blockId?.toString(),
            tabId : this.$route.query.tabId?.toString()
          },
        });
      })
    })
    .catch(() => {
      this.$router.push({name: 'login'})
    })
  }
}
