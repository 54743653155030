import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06fdce0b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_SocketsManager = _resolveComponent("SocketsManager")!
  const _component_PanelsLayout = _resolveComponent("PanelsLayout")!
  const _component_FlowChart = _resolveComponent("FlowChart")!
  const _component_NodeDetail = _resolveComponent("NodeDetail")!
  const _component_LinkDetail = _resolveComponent("LinkDetail")!
  const _component_ValidationErrorsModal = _resolveComponent("ValidationErrorsModal")!
  const _component_DeploymentModal = _resolveComponent("DeploymentModal")!
  const _component_SubscriptionStatusMessage = _resolveComponent("SubscriptionStatusMessage")!
  const _component_ModalCGU = _resolveComponent("ModalCGU")!
  const _component_KC = _resolveComponent("KC")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Spinner, {
      dark: true,
      fullScreen: true,
      class: _normalizeClass(["spinner", {hidden: !_ctx.computedLoading}])
    }, null, 8, ["class"]),
    (!_ctx.computedLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_SocketsManager),
          _createVNode(_component_PanelsLayout),
          _createVNode(_component_FlowChart),
          _createVNode(_component_NodeDetail),
          _createVNode(_component_LinkDetail),
          _createVNode(_component_ValidationErrorsModal),
          _createVNode(_component_DeploymentModal),
          _createVNode(_component_SubscriptionStatusMessage),
          (!_ctx.CGUAccepted)
            ? (_openBlock(), _createBlock(_component_ModalCGU, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_KC)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}