import API from "@/api/wrapper"
import { APIUrlRedirection } from "@/typesAPI"

// Console
interface Console {
    log:any,
    logLevel: number
}

// Ressources
interface metaGeneratedAt {
    id:string,
    meta : {
        generated_at:string
    }
}

const helpers = {
    ressources: {
        isNewerThan(r1: metaGeneratedAt, r2: metaGeneratedAt):boolean {
 
            // For handeling ms in the format send by the API
            const troncR1a = r1.meta.generated_at.split(':')
            const troncR1b = troncR1a.splice(troncR1a.length - 2 , 2)

            const troncR2a = r2.meta.generated_at.split(':')
            const troncR2b = troncR2a.splice(troncR2a.length - 2 , 2)

            if(troncR1a.join(':') === troncR2a.join(':')) {
                return parseInt(troncR1b.join(':')) > parseInt(troncR2b.join(':'))
            }
            return new Date(troncR1a.join(':')) > new Date(troncR2a.join(':'))
        }
    },
    contact: {
        contactUs () {
            window.open("mailto:support@fransys.cloud", "_blank");
        }
    },
    links: {
        newTab (url:string | undefined) {
            if(url) {
                window.open(url, "_blank");
            }
        },
        redirect (url:string | undefined) {
            if(url) {
                window.location.href = url
            }
        }
    },
    subscription: {
        checkout () {
            return API.subscription.checkout()
            .then((urlRedirection:APIUrlRedirection) => {
                window.location.href = urlRedirection.attributes.url
            })
            .catch()
        }
    },
    images: {
        dynamicLoad(path:string):string | undefined {
            try {
                return require(path) + ""
            } catch {
                return undefined
            }
        }
    },
    clipboard: {
        copy (text:string) {
            const dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
        }
    },
    components: {
        getNameFromAttributesType(type:string) {
            const splitedType = type.split('-')
            const capitalized = splitedType.map((str:string) => {
                return str.charAt(0).toUpperCase() + str.slice(1)
            })
            return 'DetailField' + capitalized.join('')
        }
    },    
    console: {
        logLevel: 0,
        log(...messages:any) {
            if(this.logLevel === 0) {
                console.log(...messages)
            }
        },
        warn(...messages:any) {
            if(this.logLevel === 0) {
                console.warn(...messages)
            }
        },
        error(...messages:any) {
            if(this.logLevel === 0) {
                console.error(...messages)
            }
        }
    }
}


export default helpers;