
import { Vue, Options } from 'vue-class-component';
import Badge from '@/components/UIElements/Badge.vue';
import Logo from '@/components/UIElements/Logo.vue';

@Options({
  components: {
    Logo,
    Badge,
  },
})
export default class FransysSignature extends Vue {
}
