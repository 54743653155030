
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Popin from '@/components/UIElements/Popin.vue';
import { Vue, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Spinner from '@/components/UIElements/Spinner.vue';
import Icon from '@/components/UIElements/Icon.vue';
import { DateTime, Interval, Duration } from "luxon";


enum PENDING_STATE {
  NONE,
  WAITING,
  SUCESS,
  FAIL
}

@Options({
  components: {
    CustomButton,
    Popin,
    Spinner,
    Icon
  },
})
export default class SubscriptionStatusMessage extends Vue {
  loadingCheckout = false
  btnStatus = ''
  pendingSuccess = true
  pendingFail = false
  PENDING_STATE = PENDING_STATE
  pendingState:PENDING_STATE = PENDING_STATE.NONE
  isTransitioning = false
  timer = ""
  
  mounted(): void {
    this.loadingCheckout = false

    if(localStorage.getItem("paymentPending") === "1") {
      this.pendingState = PENDING_STATE.WAITING
      this.checkInitPendingState()
    }

    this.calculateTimer()
    setInterval(() => {
      this.calculateTimer()
    },30000)
    
  }

  calculateTimer () {
    const expireAt = this.$store.getters['user/getUser'].meta.subscription_expires_at
    const nowDate = DateTime.now()
    const expireAtDate = DateTime.fromISO(expireAt)
    const i = Interval.fromDateTimes(nowDate, expireAtDate);
    this.timer = i.toDuration().shiftTo('hours', 'minutes').toHuman({ unitDisplay: 'narrow', maximumFractionDigits: 0 }).split(',').join('')
  }

  checkout() {
    this.loadingCheckout = true
    this.$helpers.subscription.checkout()
    .catch(() => {
      this.btnStatus = this.$enums.ButtonStates.Danger
      setTimeout(() => {
        this.btnStatus = ''
      },5000)
    })
    .finally(() => {
      this.loadingCheckout = false
    })
  }

  transitionTo(newState:PENDING_STATE) {
    this.isTransitioning = true
    setTimeout(() => {
      this.pendingState = newState
      this.isTransitioning = false
    },350)
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }

  get hidden() {
    return this.subscriptionState !== this.$enums.SubscriptionState.TRIALING && this.subscriptionState !== this.$enums.SubscriptionState.TRIAL_EXPIRED && this.subscriptionState !== this.$enums.SubscriptionState.EXPIRED && this.pendingState === PENDING_STATE.NONE
  }

  get shouldDisplayPendingMessage ():boolean {
    return this.pendingState === PENDING_STATE.WAITING
  }

  checkInitPendingState () {
    if(this.subscriptionState && this.pendingState === PENDING_STATE.WAITING) {
      if(this.subscriptionState === this.$enums.SubscriptionState.ACTIVE) {
        this.transitionTo(PENDING_STATE.SUCESS)
      } else {
        this.transitionTo(PENDING_STATE.FAIL)
      }
      setTimeout(() => {
        this.transitionTo(PENDING_STATE.NONE)
      },5000)
      localStorage.setItem("paymentPending", "0")
    }
  }

  @Watch('subscriptionState', {deep:true})
  onSubscriptionStateChange() {
    this.checkInitPendingState()
  }

}
