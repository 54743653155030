

type State = {
  currentProjectId: string
}

const state: State = {
  currentProjectId: ""
}

const getters = {
  getCurrentProjectId: (state:State) => {
    return state.currentProjectId
  },
}

const mutations = {
  SET_CURRENT_PROJECT_ID: (state:State, projectId:string) => {
    state.currentProjectId = projectId
  },
}

const actions = {
  setCurrentProjectId: (context:any, projectId:string) => {
    context.commit('SET_CURRENT_PROJECT_ID', projectId)
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}