import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39e6d506"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block-detail-top" }
const _hoisted_2 = { class: "my-auto ml-4 mr-2 block-detail-header-icon" }
const _hoisted_3 = { class: "block-detail-header-text" }
const _hoisted_4 = { class: "block-detail-bottom" }
const _hoisted_5 = { class: "btn-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_list = _resolveComponent("list")!
  const _component_NodeDetailContent = _resolveComponent("NodeDetailContent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block-detail-container", {'opened': _ctx.isOpened, 'error-validation-modal-is-open': _ctx.errorsValidationModalIsOpen, transparent: _ctx.isModalTransparent}]),
    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
  }, [
    (_ctx.block)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["block-detail bar", {compact: _ctx.isOnlyOneTab}]),
          onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_row, { class: "ma-0 pa-0 block-detail-header" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "ma-0 pa-0 d-flex" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-0 pa-0 my-auto" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          (_ctx.blockAPI && _ctx.$enums.EnginesLogos[_ctx.blockAPI.attributes.default_name])
                            ? (_openBlock(), _createBlock(_component_Logo, {
                                key: 0,
                                logo: _ctx.$enums.EnginesLogos[_ctx.blockAPI.attributes.default_name]
                              }, null, 8, ["logo"]))
                            : (_openBlock(), _createBlock(_component_Icon, {
                                key: 1,
                                icon: _ctx.block.icon
                              }, null, 8, ["icon"]))
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_v_text_field, {
                            variant: "plain",
                            density: "compact",
                            "single-line": "",
                            "hide-details": "",
                            modelValue: _ctx.editedName,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editedName) = $event)),
                            class: "token-text-medium block-detail-title",
                            onBlur: _ctx.onNameEdit,
                            onKeypress: _withKeys(_ctx.onNameEdit, ["enter"])
                          }, null, 8, ["modelValue", "onBlur", "onKeypress"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  class: "ma-0 pa-0 my-auto mr-4 block-detail-header-x",
                  cols: "1",
                  align: "right"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CustomButton, {
                      level: _ctx.$enums.ButtonLevels.SmallIcon,
                      icon: "x",
                      onClick: _ctx.onClose
                    }, null, 8, ["level", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "ma-0 pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  class: _normalizeClass(["ma-0 pa-0 block-detail-left", {closed:_ctx.listConfigTab.length <= 1}])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_list, {
                      items: _ctx.listConfigTab,
                      onSelect: _ctx.onSelectConfigTab
                    }, null, 8, ["items", "onSelect"])
                  ]),
                  _: 1
                }, 8, ["class"]),
                _createVNode(_component_v_col, { class: "ma-0 pa-0 block-detail-right" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, {
                      class: _normalizeClass(["block-detail-content", {compact: _ctx.listConfigTab.length === 1}])
                    }, {
                      default: _withCtx(() => [
                        (_ctx.currentConfigTab)
                          ? (_openBlock(), _createBlock(_component_NodeDetailContent, {
                              key: 0,
                              block: _ctx.block,
                              node: _ctx.node,
                              currentTab: _ctx.currentConfigTab,
                              isOnlyOneTab: _ctx.isOnlyOneTab,
                              "onSave:start": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSave(false))),
                              "onSave:success": _ctx.onSaveSuccess
                            }, null, 8, ["block", "node", "currentTab", "isOnlyOneTab", "onSave:success"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["class"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_CustomButton, {
                level: _ctx.$enums.ButtonLevels.Tertiary,
                icon: "trash",
                text: "Delete",
                class: "delete-btn footer-btn",
                onClick: _ctx.deleteBlock
              }, null, 8, ["level", "onClick"]),
              _createVNode(_component_CustomButton, {
                level: _ctx.$enums.ButtonLevels.Primary,
                text: _ctx.saveBtnTxt,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSave(true))),
                status: _ctx.saveBtnStatus,
                loading: _ctx.saving,
                class: "footer-btn mr-4 ml-auto my-auto"
              }, null, 8, ["level", "text", "status", "loading"])
            ])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}