
import { Vue, Options } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import API from '@/api/wrapper'

@Options({
  components: {
    Modal,
    CustomButton
  },
})
export default class ModalCGU extends Vue {
  loading = false
  visible = true
  visibleFallback = false

  reject () {
    this.visible = false
    this.visibleFallback = true
  }

  accept () {
    this.loading = true
    API.user.acceptCGU(this.$store.getters['user/getUser'].id)
    .then(() => {
      this.visible = false
    })
    .catch()
    .finally(() => {
      this.loading = false
    })
  }

  reopen () {
    this.visible = true
    this.visibleFallback = false
  }

}
